import { States } from '../config';
import { Logic } from '../index';

import { State } from './State';

export class WinLinePresentation extends State {
  public name: States = States.WIN_LINE_PRESENTATION;

  public nodes: Map<States, State> = new Map();

  public static the = new WinLinePresentation();

  private constructor() {
    super();
  }

  public enterState(prevState: States): void {
    Logic.the.controller.enterWinLinePresentationState(prevState);
  }

  public exitState(nextState: States): void {
    Logic.the.controller.exitWinLinePresentationState(nextState);
  }
}
