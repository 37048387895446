import { LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import { BaseDragon, BonusDragons } from '../slotMachine/dragon/dragon.model';
import { freeSpinsBg, freeSpinsDragonSprite } from '../slotMachine/freeSpins/freeSpins.model';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.a },
  [SlotId.B]: { default: ResourceTypes.b },
  [SlotId.C]: { default: ResourceTypes.c },
  [SlotId.D]: { default: ResourceTypes.d },
  [SlotId.E]: { default: ResourceTypes.e },
  [SlotId.F]: { default: ResourceTypes.f },
  [SlotId.G]: { default: ResourceTypes.g },
  [SlotId.H]: { default: ResourceTypes.h },
  [SlotId.I]: { default: ResourceTypes.i },
  [SlotId.SC]: { default: ResourceTypes.sc },
  [SlotId.WL]: { default: ResourceTypes.wl },
  [SlotId.WL_A]: { default: ResourceTypes.wlA },
  [SlotId.WL_B]: { default: ResourceTypes.wlB },
  [SlotId.WL_C]: { default: ResourceTypes.wlC },
};

export const MAPPED_BLURRED_SYMBOLS = {};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

type AnimationSymbols = Record<
  Partial<SlotId>,
  {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  }
>;

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: AnimationSymbols = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_high_orcus',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_high_cyclops',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_high_werewolf',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_high_goblin',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_low_spades',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_low_hearts',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_low_diamonds',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_low_clubs',
  },
  // @TODO: add empty symbol
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'destruction',
    animation: 'destruction_low_clubs',
  },
  [SlotId.SC]: {
    type: SymbolAnimationType.SPINE,
    src: 'special_symbols',
    animation: 'scatter_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'special_symbols',
    animation: 'wild_win',
  },
  [SlotId.WL_A]: {
    type: SymbolAnimationType.SPINE,
    src: 'special_symbols',
    animation: 'wild_a_win',
  },
  [SlotId.WL_B]: {
    type: SymbolAnimationType.SPINE,
    src: 'special_symbols',
    animation: 'wild_b_win',
  },
  [SlotId.WL_C]: {
    type: SymbolAnimationType.SPINE,
    src: 'special_symbols',
    animation: 'wild_c_win',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'collect_symbol',
    animation: 'collect_symbol_land',
  },
  [SlotId.SC]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'scatter_land',
  },
};
export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.a, src: Resources.getSource(ResourceTypes.a) },
  { name: ResourceTypes.b, src: Resources.getSource(ResourceTypes.b) },
  { name: ResourceTypes.c, src: Resources.getSource(ResourceTypes.c) },
  { name: ResourceTypes.d, src: Resources.getSource(ResourceTypes.d) },
  { name: ResourceTypes.e, src: Resources.getSource(ResourceTypes.e) },
  { name: ResourceTypes.f, src: Resources.getSource(ResourceTypes.f) },
  { name: ResourceTypes.g, src: Resources.getSource(ResourceTypes.g) },
  { name: ResourceTypes.h, src: Resources.getSource(ResourceTypes.h) },
  { name: ResourceTypes.i, src: Resources.getSource(ResourceTypes.i) },
  { name: ResourceTypes.sc, src: Resources.getSource(ResourceTypes.sc) },
  { name: ResourceTypes.wl, src: Resources.getSource(ResourceTypes.wl) },
  { name: ResourceTypes.wlA, src: Resources.getSource(ResourceTypes.wlA) },
  { name: ResourceTypes.wlB, src: Resources.getSource(ResourceTypes.wlB) },
  { name: ResourceTypes.wlC, src: Resources.getSource(ResourceTypes.wlC) },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.reelBackground,
    src: Resources.getSource(ResourceTypes.reelBackground),
  },
  {
    name: ResourceTypes.reelFrame,
    src: Resources.getSource(ResourceTypes.reelFrame),
  },
  {
    name: ResourceTypes.frameFreeSpins,
    src: Resources.getSource(ResourceTypes.frameFreeSpins),
  },
  {
    name: ResourceTypes.frameSpinAndGrab,
    src: Resources.getSource(ResourceTypes.frameSpinAndGrab),
  },
  {
    name: ResourceTypes.freeSpinsDefault,
    src: Resources.getSource(ResourceTypes.freeSpinsDefault),
  },
  {
    name: ResourceTypes.freeSpinsPressed,
    src: Resources.getSource(ResourceTypes.freeSpinsPressed),
  },
  {
    name: ResourceTypes.freeSpinsHover,
    src: Resources.getSource(ResourceTypes.freeSpinsHover),
  },
  {
    name: ResourceTypes.freeSpinsDisabled,
    src: Resources.getSource(ResourceTypes.freeSpinsDisabled),
  },
  {
    name: 'collect_symbol',
    src: '/animations/symbols/collect_symbol.json',
  },
  {
    name: 'money_symbol',
    src: '/animations/symbols/money_symbol.json',
  },
  {
    name: 'destruction',
    src: '/animations/symbols/destruction.json',
  },
  {
    name: 'vfx_wild_fs',
    src: '/animations/dragon/vfx_wild_fs.json',
  },
  {
    name: 'awaking_meter',
    src: '/animations/awaking_meter/awaking_meter.json',
  },
  {
    name: 'special_symbols',
    src: '/animations/symbols/special_symbols.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/special_messages.json',
  },
  {
    name: 'popups',
    src: '/animations/popups/pop_ups.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'multipliersSprite',
    src: '/images/multipliers/multipliers.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: 'ambient',
    src: '/animations/ambient/ambient.json',
  },
  {
    name: 'reelCover',
    src: '/animations/reelCover/reel_cover.json',
  },
  {
    name: freeSpinsBg,
    src: '/images/free-spins/dragon-select-popup-bg.png',
  },
  {
    name: freeSpinsDragonSprite,
    src: '/images/free-spins/dragons.json',
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.slotBgFreeSpins,
    src: Resources.getSource(ResourceTypes.slotBgFreeSpins),
  },
  {
    name: ResourceTypes.reel5Background,
    src: Resources.getSource(ResourceTypes.reel5Background),
  },
  {
    name: ResourceTypes.counterFreeSpins,
    src: Resources.getSource(ResourceTypes.counterFreeSpins),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisable),
  },
  {
    name: ResourceTypes.buyButtonDefault,
    src: Resources.getSource(ResourceTypes.buyButtonDefault),
  },
  {
    name: ResourceTypes.buyButtonHover,
    src: Resources.getSource(ResourceTypes.buyButtonHover),
  },
  {
    name: ResourceTypes.buyButtonDisabled,
    src: Resources.getSource(ResourceTypes.buyButtonDisabled),
  },
  {
    name: ResourceTypes.buyButtonPressed,
    src: Resources.getSource(ResourceTypes.buyButtonPressed),
  },
  {
    name: ResourceTypes.buyFeatureXBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtn),
  },
  {
    name: ResourceTypes.buyFeatureXBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureXBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureXBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.buyFeature,
    src: Resources.getSource(ResourceTypes.buyFeature),
  },
  {
    name: ResourceTypes.buyFeatureDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureDisable),
  },
  {
    name: ResourceTypes.buyFeatureHover,
    src: Resources.getSource(ResourceTypes.buyFeatureHover),
  },
  {
    name: ResourceTypes.buyFeaturePressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePressed),
  },
  {
    name: ResourceTypes.subTitleBackplate,
    src: Resources.getSource(ResourceTypes.subTitleBackplate),
  },
  {
    name: ResourceTypes.buyFeaturePopupRage,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupRage),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.buyFeaturePopupBackplate,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupBackplate),
  },
  {
    name: ResourceTypes.spinAndGrabRespins,
    src: Resources.getSource(ResourceTypes.spinAndGrabRespins),
  },
  {
    name: ResourceTypes.gameLogo,
    src: Resources.getSource(ResourceTypes.gameLogo),
  },
  {
    name: 'paylines',
    src: '/animations/paylines/line.json',
    isSpine: true,
    spineImages: ['line.png'],
  },
  {
    name: 'anticipation',
    src: '/animations/anticipation/anticipation.json',
    isSpine: true,
    spineImages: ['anticipation.jpg'],
  },
  {
    name: BaseDragon.kid,
    src: '/animations/kidDragon/kid_dragon.json',
  },
  {
    name: BonusDragons.fire,
    src: '/animations/fireDragon/fire_dragon.json',
  },
  {
    name: BonusDragons.ice,
    src: '/animations/iceDragon/ice_dragon.json',
  },
  {
    name: BonusDragons.thunder,
    src: '/animations/thunderDragon/thunder_dragon.json',
  },
  {
    name: 'phoenixMovie',
    src: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
