import type { Spine } from 'pixi-spine';
import { Container, Loader, Sprite, Spritesheet, Texture } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { getFromMappedSymbol, is4SlotsReel } from '../../utils';
import { multiplierTextStyle } from '../buyFeature/textStyles';
import { TextField } from '../components/TextField';
import { SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_SCALE, SLOT_WIDTH } from '../config';

class Slot extends Container {
  public id: number;

  public slotId: SlotId;

  private reelId: number | undefined;

  public textureSlotId: SlotId;

  public slot: Sprite;

  private landAnimation!: Spine;

  public multiplierValue = 0;

  public slotMoneyMultiplier: Sprite | undefined;

  public slotCollectMultiplier: TextField | null = null;

  private multipliers: Spritesheet;

  constructor(id: number, slotId: SlotId, reelId?: number) {
    super();
    this.id = id;
    this.slotId = slotId;
    this.reelId = reelId;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.y =
      ((is4SlotsReel(this.reelId as number) ? SLOTS_PER_REEL_AMOUNT + 1 : SLOTS_PER_REEL_AMOUNT) - id - 0.5) *
        SLOT_HEIGHT -
      (is4SlotsReel(this.reelId as number) ? SLOT_HEIGHT / 2 : 0);
    this.x = SLOT_WIDTH / 2;
    this.zIndex = 1;
    this.slot = this.initSlot();
    this.addChild(this.slot);
    this.multipliers = Loader.shared.resources!['multipliersSprite']!.spritesheet!;
  }

  private initSlot(): Sprite {
    const slot = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, this.slotId)));
    slot.anchor.set(0.5, 0.5);
    return slot;
  }

  public removeMoneyMultiplier(): void {
    this.slot.removeChild(this.slotMoneyMultiplier!);
  }

  public updateMoneyMultiplier(value: number): void {
    this.multiplierValue = value;
    this.slotMoneyMultiplier!.texture = this.multipliers.textures[value as number]!;
  }

  public changeTexture(slotId: SlotId): void {
    this.slot.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public changeSlot(slotId: SlotId): void {
    this.changeTexture(slotId);
    this.slotId = slotId;
  }

  public toggleBlur(_blur: boolean): void {
    this.slot.texture = Texture.from(MAPPED_SYMBOLS[this.textureSlotId].default);

    //TODO remove after refactoring
    // if (blur) {
    //   this.slot.texture = Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId].default);
    // } else {
    //   this.slot.texture = Texture.from(MAPPED_SYMBOLS[this.textureSlotId].default);
    // }
  }

  public onSlotStopped(): void {
    // @TODO ADD add sound/animation on slot stop
    this.playLandAnim();
  }

  private playLandAnim(): void {
    // No land anim on this game
    return;
  }

  public setCollectMultiplier(collectorValue: number): void {
    this.multiplierValue = collectorValue;
    const multiplierText = new TextField(`x${this.multiplierValue}`, SLOT_WIDTH, SLOT_HEIGHT, multiplierTextStyle);
    this.slotCollectMultiplier = multiplierText;
    multiplierText.text.anchor.set(0.5, 0.5);
    this.addChild(multiplierText.getText());
  }

  public resetCollectMultiplier(): void {
    if (this.slotCollectMultiplier) {
      this.removeChild((this.slotCollectMultiplier as TextField).getText());
    }
    if (this.landAnimation) {
      this.removeChild(this.landAnimation);
    }
    if (this.children[0]) {
      (this.children[0] as Sprite).removeChildren();
    }
  }
}

export default Slot;
