import { EventTypes } from '../../global.d';
import { ViewContainer } from '../components/ViewContainer';
import { GAME_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import type { IWinLine } from '../d';

import Line from './payline';

class PaylinesContainer extends ViewContainer {
  public paylinesData: number[][];

  public paylines: Line[];

  constructor(paylinesData: number[][]) {
    super();
    this.paylinesData = paylinesData;
    this.paylines = [];
    this.y = 400;
    this.x = GAME_CONTAINER_WIDTH / 2;
    this.initContainer();
    this.initLines();
    eventManager.addListener(EventTypes.SHOW_WIN_LINES, this.showLines.bind(this));
    eventManager.addListener(EventTypes.HIDE_WIN_LINES, this.hideLines.bind(this));
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private initLines(): void {
    for (let i = 0; i < this.paylinesData.length; i++) {
      const line = new Line(i);
      this.paylines.push(line);
      this.addChild(line);
    }
  }

  public showLines(lines: IWinLine[]): void {
    lines.forEach((line) => {
      if (line.lineId !== null) this.setLineVisibility(line.lineId, true);
    });
  }

  public hideLines(lines: IWinLine[]): void {
    if (!lines) {
      for (let i = 0; i < this.paylines.length; i++) {
        this.setLineVisibility(i, false);
      }
    } else {
      lines.forEach((line) => {
        if (line.lineId !== null) this.setLineVisibility(line.lineId, false);
      });
    }
  }

  public setLineVisibility(id: number, visibility: boolean): void {
    this.paylines[id as number]!.show(visibility);
  }
}

export default PaylinesContainer;
