import { TextStyle } from 'pixi.js';

import type { FormFactorSizes } from '../../awakening-meter/awakening-meter.model';

export const buyFeaturePopupSizes: FormFactorSizes = {
  pc: { x: 'center', y: 'center', width: 656, height: 560 },
  portrait: { x: 0, y: 'center', width: 'full', height: 360 },
  landscape: { x: 'center', y: 0, width: 390, height: 360 },
};

export const buyFeatureWhiteText = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 38,
  lineHeight: 52,
  fontWeight: 'bold',
  stroke: '#013f2b',
  strokeThickness: 4,
  whiteSpace: 'normal',
  miterLimit: 1,
});

export const buyFeatureYellowText = new TextStyle({
  align: 'center',
  fill: '#fbff34',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 28,
  lineHeight: 38,
  fontWeight: 'bold',
  stroke: '#493108',
  strokeThickness: 4,
  whiteSpace: 'normal',
});

export const buyFeatureBetAmount = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 28,
  lineHeight: 38,
  fontWeight: 'bold',
  stroke: '#493108',
  strokeThickness: 3,
  whiteSpace: 'normal',
  miterLimit: 1,
});
