import type { ITrackEntry } from 'pixi-spine';

export interface TrackWithName extends ITrackEntry {
  animation: {
    name: string;
  };
}
export enum BonusDragons {
  fire = 'fire',
  ice = 'ice',
  thunder = 'thunder',
}

export enum BaseDragon {
  kid = 'kid',
}

export enum BaseDragonMovements {
  idle = 'idle',
  attack = 'attack',
}

export enum BonusDragonMovements {
  idle = 'idle',
  head_a_attack = 'head_a_attack',
  head_b_attack = 'head_b_attack',
  left_arm_attack = 'left_arm_attack',
  right_arm_attack = 'right_arm_attack',
}
