import type { Container } from 'pixi.js';
import { isMobile } from 'pixi.js';

interface Sizes {
  x: Position;
  y: Position;
  width: Size;
  height: Size;
}
type Size = number | 'full' | 'half';

type Position = number | 'center';
export interface FormFactorSizes {
  pc: Sizes;
  portrait: Sizes;
  landscape: Sizes;
}

export const MAX_AWAKENING_LEVEL = 7;

export const awakeningMeterPosition = {
  portrait: { leftMode: { x: 350, y: 950 }, rightMode: { x: 750, y: 950 } },
  landscape: { leftMode: { x: -30, y: 430 }, rightMode: { x: 1150, y: 430 } },
};

export const multiplierPosition = {
  pc: { x: 1150, y: 265 },
  portrait: { leftMode: { x: 750, y: 950 }, rightMode: { x: 350, y: 950 } },
  landscape: { leftMode: { x: -30, y: 250 }, rightMode: { x: 1150, y: 250 } },
};

const FHD_WIDTH = 1920;
const MOBILE_PORTRAIT_WIDTH = 390;
const MOBILE_PORTRAIT_HEIGHT = 844;

export function setSizesByFormFactor(container: Container, { pc, portrait, landscape }: FormFactorSizes) {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const isPortrait = isMobile.any && screenHeight > screenWidth;

  if (!isMobile.any) {
    const rationX = screenWidth / FHD_WIDTH;

    container.x = getPosition(pc.x, getSize(pc.width, screenWidth, rationX), screenWidth, rationX);
    container.y = getPosition(pc.y, getSize(pc.height, screenHeight, rationX), screenHeight, rationX);
    container.width = getSize(pc.width, screenWidth, rationX);
    container.height = getSize(pc.height, screenHeight, rationX);

    return;
  }

  if (isPortrait) {
    const rationX = screenWidth / MOBILE_PORTRAIT_WIDTH;

    container.x = getPosition(portrait.x, getSize(portrait.width, screenWidth, rationX), screenWidth, rationX);
    container.y = getPosition(portrait.y, getSize(portrait.height, screenHeight, rationX), screenHeight, rationX);
    container.width = getSize(portrait.width, screenWidth, rationX);
    container.height = getSize(portrait.height, screenHeight, rationX);

    return;
  }

  if (!isPortrait) {
    const rationX = screenWidth / MOBILE_PORTRAIT_HEIGHT;

    container.x = getPosition(landscape.x, getSize(landscape.width, screenWidth, rationX), screenWidth, rationX);
    container.y = getPosition(landscape.y, getSize(landscape.height, screenHeight, rationX), screenHeight, rationX);
    container.width = getSize(landscape.width, screenWidth, rationX);
    container.height = getSize(landscape.height, screenHeight, rationX);
  }
}

export function getSize(configSize: Size, containerSize: number, ratio: number): number {
  if (configSize === 'full') {
    return containerSize;
  }
  if (configSize === 'half') {
    return containerSize / 2;
  }
  return configSize * ratio;
}

export function getPosition(configPosition: Position, size: number, containerSize: number, ratio: number): number {
  return configPosition === 'center' ? (containerSize - size) / 2 : configPosition * ratio;
}
