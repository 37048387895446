import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';
import { Rectangle } from 'pixi.js';

import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsPopupOpened,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { isRegularMode } from '../../utils';
import { eventManager } from '../config';
import { ControlButton } from '../controlButtons';

class InfoBtn extends ControlButton {
  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  constructor() {
    super('menu', 75);
    this.interactive = !this.isDisabled;
    this.btnSheet = PIXI.Loader.shared.resources['infoBtnSprite']!.spritesheet!;
    this.intent = 'infomation';
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    if (isMobile) {
      this.btn.hitArea = new Rectangle(-75, -75, 150, 150);
    }
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE) {
      setIsModalOpeningInProgress(ModalOpeningTypes.MENU);

      setIsOpenMenuModal({
        isOpen: !setIsOpenMenuModal().isOpen,
        type: 'info',
      });
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsPopupOpened(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(Logic.the.controller.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        setIsPopupOpened(),
    );
  };
}

export default InfoBtn;
