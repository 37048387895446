import { Container, Loader, Sprite } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { eventManager } from '../config';

export class ModalService {
  static _instanceCache: ModalService;

  static get the() {
    if (!this._instanceCache) {
      this._instanceCache = new this();
    }

    return this._instanceCache;
  }
  private openModals: Container[] = [];

  public get isModalVisible() {
    return this.modalContainer.children.length;
  }
  private modalContainer = new Container();

  public open(component: Container) {
    const subModalContainer = new Container();
    const backdrop = new Sprite(Loader.shared.resources['backdrop']?.texture);
    backdrop.zIndex = 2;
    this.modalContainer.visible = true;
    this.modalContainer.interactive = true;
    subModalContainer.addChild(backdrop, component);
    this.modalContainer.addChild(subModalContainer);
    this.openModals.push(subModalContainer);
    eventManager.emit(EventTypes.OPEN_POPUP);
    // this.setBackdropClickZone(backdrop);

    // this.alignToCenterBody(component);
  }

  public setBackdropClickZone(backdrop: Container): void {
    backdrop.interactive = true;
    backdrop.on('pointertap', () => {
      this.hide(true);
    });
  }

  public hide(playCloseSound = false): void {
    const subModalContainer = this.openModals.pop();

    if (!subModalContainer) return;

    if (playCloseSound) AudioApi.play({ type: ISongs.SFX_UI_Close });

    this.modalContainer.removeChild(subModalContainer);
    eventManager.emit(EventTypes.CLOSE_POPUP);
  }

  public registerModal(container: Container) {
    this.modalContainer.zIndex = 3;
    container.addChild(this.modalContainer);
  }

  private alignToCenterBody(element: Container): void {
    element.x = (window.innerWidth - element.width * this.modalContainer.scale.x) / 2 / this.modalContainer.scale.x;
    element.y = (window.innerHeight - element.height * this.modalContainer.scale.y) / 2 / this.modalContainer.scale.y;
  }
}
