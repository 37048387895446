import _ from 'lodash';

import type { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BGM_BG_Base_Loop = '015_01_BaseGameBGM_Base',
  BGM_BG_Melo_Loop = '015_02_BaseGameBGM_Melo',
  // BGM_SG_Loop = '013_03_BGM_SG_Loop',
  BGM_FS_Loop = '015_03_FreeSpinBGM',
  // SG_EntrancePopup = '013_05_SG_EntrancePopup',
  // FS_EntrancePopup = '013_06_FS_EntrancePopup',
  WinPopup = '015_04_TotalWinBanner',
  BigWin_Loop = '015_05_BigWin_Loop',
  BigWin_End = '015_06_BigWin_END',
  BGM_SD_Loop = '015_07_Select_Dragons',
  // SmallWin = '013_14_SmallWin',
  // MediumWin = '013_15_MediumWin',
  // HighWin = '013_16_HighWin',
  ScatterLanding1 = '015_11_Scatter_01',
  ScatterLanding2 = '015_12_Scatter_02',
  ScatterLanding3 = '015_13_Scatter_03',
  LongSpin = '015_14_LongSpin',
  AwakeningMeter = '015_15_Kid_Dragon',
  DragonAttackStart = '015_16_Dragon_Short',
  DragonTypeSelection = '015_17_Dragon_Long',
  KidDragonAttack = '015_18_Dragon_Fire',
  FireDragonAttack = '015_19_Dragon_Blaze',
  IceDragonAttack = '015_20_Dragon_Ice',
  ThunderDragonAttack = '015_21_Dragon_Thunder',
  // MoneyLanding = '013_18_MoneyLanding',
  // CollectLanding = '013_19_CollectLanding',
  // CollectWin = '013_21_CollectWin',
  // ValuesGathering = '013_22_ValuesGathering',
  SFX_WIN_FeatureTrigger = '015_22_FeatureTrigger',
  FeatureDragonWing = '015_23_Dragon_Wing',
  // SpinAndGrabDoubleUp = '013_25_DoubleUp',
  BuyButton = 'SFX_BuyFeature',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_phoenix = 'SFX_phoenix',
  Win_Big = 'SFX_Win_Big',
  Win_Epic = 'SFX_Win_Epic',
  Win_Great = 'SFX_Win_Great',
  Win_Mega = 'SFX_Win_Mega',
}

export const audioSprite: AudioSprite = {
  [ISongs.BGM_BG_Base_Loop]: [0, 60952.42630385488, true],
  [ISongs.BGM_BG_Melo_Loop]: [62000, 60954.69387755102, true],
  // [ISongs.BGM_SG_Loop]: [108000, 52365.351473922885, true],
  [ISongs.BGM_FS_Loop]: [124000, 60000, true],
  // [ISongs.SG_EntrancePopup]: [222000, 3574.4217687074806],
  // [ISongs.FS_EntrancePopup]: [227000, 3577.3242630385485],
  [ISongs.WinPopup]: [185000, 5821.315192743754],
  [ISongs.BigWin_Loop]: [192000, 30000.045351473917, true],
  [ISongs.BigWin_End]: [224000, 5874.784580498868],
  [ISongs.BGM_SD_Loop]: [231000, 29090.929705215443, true],

  // [ISongs.SmallWin]: [280000, 1786.4852607709736],
  // [ISongs.MediumWin]: [283000, 1783.5827664399062],
  // [ISongs.HighWin]: [286000, 2677.5510204081456],
  [ISongs.ScatterLanding1]: [262000, 928.3446712018417],
  [ISongs.ScatterLanding2]: [264000, 1020.136054421755],
  [ISongs.ScatterLanding3]: [267000, 1245.487528344654],
  [ISongs.LongSpin]: [270000, 2446.7120181406017],
  [ISongs.AwakeningMeter]: [274000, 1015.3287981859194],
  [ISongs.DragonAttackStart]: [277000, 2042.1315192743918],
  [ISongs.DragonTypeSelection]: [281000, 3345.62358276645],
  [ISongs.KidDragonAttack]: [286000, 887.8458049886717],
  [ISongs.FireDragonAttack]: [288000, 2567.800453514735],
  [ISongs.IceDragonAttack]: [292000, 2403.4467120181375],
  [ISongs.ThunderDragonAttack]: [296000, 3116.3718820861845],
  [ISongs.SFX_WIN_FeatureTrigger]: [301000, 1033.3333333333599],
  [ISongs.FeatureDragonWing]: [304000, 1364.0362811791533],
  [ISongs.BuyButton]: [307000, 666.6666666666856],
  // [ISongs.MoneyLanding]: [293000, 1017.3242630385744],
  // [ISongs.CollectLanding]: [296000, 872.1995464852625],
  // [ISongs.CollectWin]: [298000, 1516.5532879818784],
  // [ISongs.ValuesGathering]: [301000, 1510.7482993197436],
  // [ISongs.SpinAndGrabDoubleUp]: [311000, 1789.387755102041],
  [ISongs.SFX_SM_CountUp_End]: [309000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [311000, 399.77324263037417, true],
  [ISongs.SFX_UI_AutoSpin]: [313000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [315000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [317000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [319000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [321000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [323000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [325000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [327000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [329000, 151.02040816327644],
  [ISongs.Win_Big]: [331000, 1622.358276643979],
  [ISongs.Win_Epic]: [334000, 1862.2675736961583],
  [ISongs.Win_Great]: [337000, 2118.412698412726],
  [ISongs.Win_Mega]: [341000, 1696.190476190452],
  [ISongs.SFX_phoenix]: [344000, 4041.723356009072],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Base_Loop]: 0.4,
  [ISongs.BGM_BG_Melo_Loop]: 0.45,
  // [ISongs.BGM_SG_Loop]: 0.4,
  [ISongs.BGM_FS_Loop]: 0.4,
  // [ISongs.SG_EntrancePopup]: 0.5,
  // [ISongs.FS_EntrancePopup]: 0.5,
  [ISongs.WinPopup]: 0.5,
  [ISongs.BigWin_Loop]: 0.5,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.BGM_SD_Loop]: 0.4,

  // [ISongs.SmallWin]: 0.25,
  // [ISongs.MediumWin]: 0.25,
  // [ISongs.HighWin]: 0.25,
  [ISongs.ScatterLanding1]: 0.4,
  [ISongs.ScatterLanding2]: 0.4,
  [ISongs.ScatterLanding3]: 0.4,
  // [ISongs.MoneyLanding]: 0.4,
  // [ISongs.CollectLanding]: 0.5,
  // [ISongs.CollectWin]: 0.5,
  // [ISongs.ValuesGathering]: 0.25,
  [ISongs.LongSpin]: 0.4,
  [ISongs.AwakeningMeter]: 0.4,
  [ISongs.DragonAttackStart]: 0.4,
  [ISongs.DragonTypeSelection]: 0.4,
  [ISongs.KidDragonAttack]: 0.6,
  [ISongs.FireDragonAttack]: 0.4,
  [ISongs.IceDragonAttack]: 0.4,
  [ISongs.ThunderDragonAttack]: 0.4,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.3,
  [ISongs.FeatureDragonWing]: 0.3,
  [ISongs.BuyButton]: 0.3,
  // [ISongs.SpinAndGrabDoubleUp]: 0.6,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.6,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.2,
  [ISongs.SFX_UI_SpinStop]: 0.2,
  [ISongs.SFX_phoenix]: 0.4,
  [ISongs.Win_Big]: 0.4,
  [ISongs.Win_Epic]: 0.4,
  [ISongs.Win_Great]: 0.4,
  [ISongs.Win_Mega]: 0.4,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
