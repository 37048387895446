import i18n from 'i18next';
import { ITextStyle, Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setCurrency, setFreeSpinsTotalWin, setIsContinueAutoSpinsAfterFeature } from '../../../gql/cache';
import { normalizeCoins, showCurrency } from '../../../utils';
import { TextField } from '../../components/TextField';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';

import FreeSpinsEndPopupBackgroundAnimated from './freeSpinsEndPopupBackgroundAnimated';

// const textStyle: Partial<ITextStyle> = {
//   align: 'center',
//   dropShadowAngle: -1.2,
//   dropShadowBlur: 9,
//   dropShadowColor: '#fff700',
//   dropShadowDistance: 0,
//   fill: '#ffea00',
//   fontFamily: 'NotoSans-SemiCondensedBold',
//   fontSize: 90,
//   fontWeight: 'bold',
//   miterLimit: 48,
//   stroke: '#2e0000',
//   strokeThickness: 1,
//   whiteSpace: 'normal',
//   wordWrapWidth: 140,
// };

const textPress: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: '#1e400a',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 70,
  stroke: '#2e0000',
  strokeThickness: 1,
  whiteSpace: 'normal',
};

const textWinAmount: Partial<ITextStyle> = {
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 2,
  dropShadowColor: '#371a06',
  dropShadowDistance: 3,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientStops: [0.1, 0.3, 0.5, 0.7],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 400,
  stroke: '#110000',
  strokeThickness: 22,
  whiteSpace: 'normal',
};

const textYouWon: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: 'white',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 125,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#2e0000',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
};

const text2Style: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: 'white',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 100,
  fontWeight: 'bold',
  miterLimit: 48,
  stroke: '#2e0000',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
};

export class FreeSpinsEndPopup extends Popup {
  protected background!: Sprite;

  protected popupBackground!: FreeSpinsEndPopupBackgroundAnimated;

  protected text!: TextField;

  protected youWonText!: TextField;

  protected congratulationText!: TextField;

  protected pressToContinueText!: TextField;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.initBackground();
    // this.initYouWonText();
    // this.initPressToContinueText();
    // this.initCongtatulationText();
    this.initWinValueText();

    this.init();
  }

  private initBackground(): void {
    this.popupBackground = new FreeSpinsEndPopupBackgroundAnimated(this);
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.background.alpha = 0.5;
  }

  private initYouWonText(): void {
    this.youWonText = new TextField(i18n.t('freeSpinsYouWon'), 500, 100, textYouWon);
    this.youWonText.text.anchor.set(0.5, 0.5);
    this.popupBackground.spine.skeleton.findSlot('you_won').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton.findSlot('you_won').currentSprite.addChild(this.youWonText.getText());
  }

  private initCongtatulationText(): void {
    this.congratulationText = new TextField(i18n.t('freeSpinsCongratulation'), 500, 100, text2Style);
    this.congratulationText.text.anchor.set(0.5, 0.5);
    this.popupBackground.spine.skeleton.findSlot('congratulations').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton
      .findSlot('congratulations')
      .currentSprite.addChild(this.congratulationText.getText());
  }

  private initWinValueText(): void {
    this.text = new TextField('', 500, 500, textWinAmount);
    this.text.text.anchor.set(0.5, 0.5);
    this.popupBackground.spine.skeleton.findSlot('123456789').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton.findSlot('123456789').currentSprite.addChild(this.text.getText());
  }

  private initPressToContinueText(): void {
    this.pressToContinueText = new TextField(i18n.t('freeSpinsPressToContinue'), 500, 100, textPress);
    this.pressToContinueText.text.anchor.set(0.5, 0.5);
    this.popupBackground.spine.skeleton.findSlot('press_anywhere').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine
      .skeleton!.findSlot('press_anywhere')
      .currentSprite.addChild(this.pressToContinueText.getText());
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.popupBackground);
    // this.popupBackground.spine.slotContainers[
    //   this.popupBackground.spine.skeleton.findSlotIndex('10_free_spins')
    // ]!.removeChildren();
  }

  public override show(_props?: PopupProps): void {
    AudioApi.stop({ type: ISongs.BGM_FS_Loop });
    AudioApi.play({ type: ISongs.WinPopup });
    this.text.setText(
      `${formatNumber({
        currency: setCurrency(),
        value: normalizeCoins(setFreeSpinsTotalWin()),
        showCurrency: showCurrency(setCurrency()),
      })}`,
    );
    this.popupBackground.show();
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    clearTimeout(this.autoSkipDelay);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide();
  };

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    const factor = (isMobile.any && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.popupBackground.scale.set(factor);
    this.position.set(width / 2, height / 2);
  }
}
