import { Spine } from 'pixi-spine';
import { Loader, Sprite, Text, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, mappedAudioSprites } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCurrency, setIsDuringBigWinLoop } from '../../gql/cache';
import { getWinStage, normalizeCoins, showCurrency } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import {
  APPLICATION_FPS,
  BIG_WIN_AMOUNT_LIMIT,
  BIG_WIN_COUNT_UP_MULTIPLIER,
  EPIC_WIN_COUNT_UP_MULTIPLIER,
  GREAT_WIN_AMOUNT_LIMIT,
  GREAT_WIN_COUNT_UP_MULTIPLIER,
  MEGA_WIN_AMOUNT_LIMIT,
  MEGA_WIN_COUNT_UP_MULTIPLIER,
  REELS_AMOUNT,
  REEL_WIDTH,
  WinStages,
  WinTextValueTextStyle,
  eventManager,
} from '../config';

export class BigWinContainer extends ViewContainer {
  protected background!: Sprite;

  private spine: Spine;

  private winValue = new Text();

  private bigWinCountUpAnimation: Animation | null = null;

  constructor() {
    super();
    this.x = (REEL_WIDTH * REELS_AMOUNT) / 2;
    this.y = 500;
    this.spine = new Spine(Loader.shared.resources['bigWinMessages']!.spineData!);
    this.spine.skeleton.findSlot('123456789').currentSprite.texture = Texture.EMPTY;
    this.addChild(this.spine);
    this.winValue.y = 140;
    this.spine.addChild(this.winValue);
    this.zIndex = 2;
    eventManager.addListener(EventTypes.START_BIG_WIN_PRESENTATION, this.startBigWinPresentation.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
  }

  private startWin(stage: string): void {
    this.spine.state.setAnimation(0, `${stage}_in`, false);
    this.spine.state.addAnimation(0, `${stage}_loop`, true, 0);
  }

  private endWin(stage: string): void {
    this.spine.state.setAnimation(0, `${stage}_out`, false);
  }

  private skipWinCountUpAnimation() {
    this.bigWinCountUpAnimation?.skip();
  }

  public startBigWinPresentation(winAmount: number): void {
    if (isMobile.any) {
      this.scale.set(0.8);
    }
    this.spine.visible = true;
    this.setWinValue(0);
    const betAmount = normalizeCoins(setBetAmount());
    const normalizedWinAmount = normalizeCoins(winAmount);
    const stage = getWinStage(winAmount);
    setIsDuringBigWinLoop(true);

    const animationChain = new AnimationChain({
      proceedNextAnimationOnSkip: true,
    });
    animationChain.addOnStart(() => {
      eventManager.emit(EventTypes.OPEN_POPUP_BG);
    });
    animationChain.addOnSkip(() => {
      this.bigWinCountUpAnimation = null;
    });
    animationChain.addOnComplete(() => {
      this.bigWinCountUpAnimation = null;
    });
    if (stage >= WinStages.BigWin) {
      const bigWinAnimationGroup = new AnimationGroup();
      const bigWinAnimation = this.createBigWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.BigWin);

      const bigWinBgmChain = new AnimationChain();
      bigWinAnimation.addOnStart(() => {
        // AudioApi.fadeOut(
        //   500,
        //   getBGMSoundByGameMode(Logic.the.controller.gameMode),
        // );
        // AudioApi.fadeOut(1000, ISongs.SG_EntrancePopup);
        // AudioApi.fadeOut(1000, ISongs.FS_EntrancePopup);
      });
      bigWinAnimationGroup.addAnimation(bigWinAnimation);
      bigWinAnimationGroup.addAnimation(bigWinBgmChain);
      animationChain.appendAnimation(bigWinAnimationGroup);
    }
    if (stage >= WinStages.MegaWin) {
      const megaWinAnimation = this.createMegaWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.MegaWin);
      animationChain.appendAnimation(megaWinAnimation);
    }
    if (stage >= WinStages.GreatWin) {
      const greatWinAnimation = this.createGreatWinAnimation(
        normalizedWinAmount,
        betAmount,
        stage === WinStages.GreatWin,
      );
      animationChain.appendAnimation(greatWinAnimation);
    }
    if (stage >= WinStages.EpicWin) {
      const epicWinAnimation = this.createEpicWinAnimation(normalizedWinAmount, betAmount, stage === WinStages.EpicWin);
      animationChain.appendAnimation(epicWinAnimation);
    }

    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winValue,
      property: TweenProperties.ALPHA,
      duration: mappedAudioSprites[ISongs.BigWin_End].duration,
    });

    fadeOutAnimation.addOnStart(() => {
      if (stage >= WinStages.BigWin) {
        if (stage === WinStages.BigWin) this.endWin('big_win');
        if (stage === WinStages.MegaWin) this.endWin('mega_win');
        if (stage === WinStages.GreatWin) this.endWin('great_win');
        if (stage === WinStages.EpicWin) this.endWin('epic_win');
        eventManager.emit(EventTypes.CLOSE_POPUP_BG);
        setIsDuringBigWinLoop(false);
        AudioApi.stop({ type: ISongs.Win_Big });
        AudioApi.stop({ type: ISongs.BigWin_Loop });
        AudioApi.play({ type: ISongs.BigWin_End });
        eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
      }
    });
    fadeOutAnimation.addOnSkip(() => {
      this.spine.state.setEmptyAnimation(0, 0);
      AudioApi.stop({ type: ISongs.BigWin_End });
      eventManager.emit(EventTypes.COUNT_UP_END);
      eventManager.emit(EventTypes.HANDLE_SKIP_FADE_ANIMATION);
    });
    fadeOutAnimation.addOnComplete(() => {
      this.spine.state.setEmptyAnimation(0, 0);
      eventManager.emit(EventTypes.COUNT_UP_END);
    });
    animationChain.appendAnimation(fadeOutAnimation);
    animationChain.addOnStart(() => {
      this.winValue.alpha = 1;
    });
    animationChain.addOnComplete(() => {
      this.clean();
    });
    animationChain.addOnSkip(() => {
      this.clean();
    });
    this.bigWinCountUpAnimation = animationChain;
    animationChain.start();
  }

  private createBigWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration =
      (Math.min(win / bet, BIG_WIN_AMOUNT_LIMIT) / (BIG_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: 0,
      target: Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startWin('big_win');
      AudioApi.fadeOut(1000, ISongs.BGM_BG_Base_Loop, 0);
      AudioApi.fadeOut(1000, ISongs.BGM_BG_Melo_Loop, 0);
      AudioApi.fadeOut(1000, ISongs.BGM_FS_Loop, 0);
      AudioApi.play({ type: ISongs.Win_Big, stopPrev: true });
      AudioApi.play({ type: ISongs.BigWin_Loop, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endWin('big_win');
        AudioApi.stop({ type: ISongs.Win_Big });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * BIG_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endWin('big_win');
        AudioApi.stop({ type: ISongs.Win_Big });
      }
    });
    return countUpAnimation;
  }

  private createMegaWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration =
      (Math.min(win / bet, MEGA_WIN_AMOUNT_LIMIT) / (MEGA_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * BIG_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startWin('mega_win');
      AudioApi.play({ type: ISongs.Win_Mega, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endWin('mega_win');
        AudioApi.stop({ type: ISongs.Win_Mega });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * MEGA_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endWin('mega_win');
        AudioApi.stop({ type: ISongs.Win_Mega });
      }
    });
    return countUpAnimation;
  }

  private createGreatWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration =
      (Math.min(win / bet, GREAT_WIN_AMOUNT_LIMIT) / (GREAT_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * MEGA_WIN_AMOUNT_LIMIT,
      target: Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT),
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startWin('great_win');
      this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT));
      AudioApi.play({ type: ISongs.Win_Great, stopPrev: true });
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endWin('great_win');
        AudioApi.stop({ type: ISongs.Win_Great });
      }
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(Math.min(win, bet * GREAT_WIN_AMOUNT_LIMIT));
      if (!isLastStage) {
        this.endWin('great_win');
        AudioApi.stop({ type: ISongs.Win_Great });
      }
    });
    return countUpAnimation;
  }

  private createEpicWinAnimation(win: number, bet: number, isLastStage: boolean): Animation {
    const duration = (win / bet / (EPIC_WIN_COUNT_UP_MULTIPLIER * APPLICATION_FPS)) * 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: bet * GREAT_WIN_AMOUNT_LIMIT,
      target: win,
      object: this.winValue,
      property: TweenProperties.TEXT,
      update: this.setWinValue.bind(this),
      duration,
    });
    countUpAnimation.addOnStart(() => {
      this.startWin('epic_win');
      AudioApi.play({ type: ISongs.Win_Epic });
    });
    countUpAnimation.addOnComplete(() => {
      this.setWinValue(win);
      if (!isLastStage) {
        this.endWin('epic_win');
        AudioApi.stop({ type: ISongs.Win_Epic });
      }
    });
    countUpAnimation.addOnSkip(() => {
      this.setWinValue(win);
      if (!isLastStage) {
        this.endWin('epic_win');
        AudioApi.stop({ type: ISongs.Win_Epic });
      }
    });

    return countUpAnimation;
  }

  private clean(): void {
    this.spine.visible = false;
    setIsDuringBigWinLoop(false);
    AudioApi.stop({ type: ISongs.BigWin_Loop });
    // AudioApi.stop({ type: ISongs.BigWin_End });
    AudioApi.stop({ type: ISongs.Win_Big });
    AudioApi.stop({ type: ISongs.Win_Mega });
    AudioApi.stop({ type: ISongs.Win_Great });
    AudioApi.stop({ type: ISongs.Win_Epic });
    AudioApi.fadeIn(1000, ISongs.BGM_BG_Base_Loop);
    AudioApi.fadeIn(1000, ISongs.BGM_BG_Melo_Loop);
    AudioApi.fadeIn(1000, ISongs.BGM_FS_Loop);
    this.spine.state.addEmptyAnimation(0, 0, 0);
    this.winValue.alpha = 0;
    this.setWinValue(0);
    this.bigWinCountUpAnimation = null;
  }

  public setWinValue(winValue: number): void {
    this.winValue.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
    this.winValue.style = WinTextValueTextStyle;
    this.winValue.anchor.set(0.5, 0.5);
  }
}
