import { Sprite, Texture, isMobile } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setIsLeftHandMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { setSizesByFormFactor } from '../awakening-meter/awakening-meter.model';
import { eventManager } from '../config';

import { logoSizes } from './logo.model';

export class Logo extends Sprite {
  public override name = 'Logo';
  constructor() {
    super(Texture.from(ResourceTypes.gameLogo));
    this.zIndex = 2;
    this.anchor.set(0.5, 0.5);
    this.setSizes();

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
  }
  private setSizes(): void {
    let isLeftMode = setIsLeftHandMode();
    let isLandscape = isMobile.any && window.innerWidth > window.innerHeight;
    setSizesByFormFactor(this, logoSizes);
    if (isLeftMode && isLandscape) {
      this.x = window.innerWidth - this.x;
    }

    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLm) => {
      if (isLeftMode === isLm) return;
      isLeftMode = isLm;
      if (isLandscape) {
        this.x = window.innerWidth - this.x;
      }
    });

    eventManager.on(EventTypes.RESIZE, () => {
      isLandscape = isMobile.any && window.innerWidth > window.innerHeight;
      setSizesByFormFactor(this, logoSizes);

      if (isLeftMode && isLandscape) {
        this.x = window.innerWidth - this.x;
      }
    });
  }

  protected onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }
}
