import { isMobile } from 'mobile-device-detect';
import { ITrackEntry, Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';

import type { FreeSpinsPopup } from './freeSpinsPopup';

class FreeSpinsPopupBackgroundAnimated extends ViewContainer {
  public spine: Spine;

  private freeSpinsPopup: FreeSpinsPopup;

  private showAnimationTrack!: ITrackEntry;

  private hideAnimationTrack!: ITrackEntry;

  constructor(popup: FreeSpinsPopup) {
    super();
    this.freeSpinsPopup = popup;
    this.spine = new Spine(Loader.shared.resources['popups']!.spineData!);
    this.addChild(this.spine);
  }

  protected override resize(width: number, height: number): void {
    const isLandscape = width >= height;
    this.spine.y = isMobile && !isLandscape ? -50 : 0;
  }

  public show(): void {
    this.freeSpinsPopup.visible = true;
    this.showAnimationTrack = this.spine.state.setAnimation(0, 'free_spins_in', false);
    this.spine.state.addAnimation(0, 'free_spins_loop', true, 0);
  }

  public hide(): void {
    this.hideAnimationTrack = this.spine.state.setAnimation(0, 'free_spins_out', false);
    this.hideAnimationTrack.listener = {
      complete: (_entry: { trackIndex: number }) => {
        this.freeSpinsPopup.visible = false;
        eventManager.emit(EventTypes.START_FREE_SPINS);
        this.spine.state.addEmptyAnimation(0, 0, 0);
      },
    };
  }
}

export default FreeSpinsPopupBackgroundAnimated;
