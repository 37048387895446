export enum SlotFeaturesConfigTypes {
  REACT_APP_SLOT_IS_BUY_FEATURE_ENABLED = `REACT_APP_SLOT_IS_BUY_FEATURE_ENABLED`,
  REACT_APP_SLOT_IS_ANIMATED_POPUPS = `REACT_APP_SLOT_IS_ANIMATED_POPUPS`,
  REACT_APP_SLOT_IS_ANIMATED_BACKGROUND = `REACT_APP_SLOT_IS_ANIMATED_BACKGROUND`,
  // ADD COLSxROWS CONFIG
  // ADD WINLINES CONFIG
}

export type ISlotFeaturesConfig = {
  [key in SlotFeaturesConfigTypes]: boolean;
};

export const SLOT_FEATURES_CONFIG = Object.keys(process.env).reduce((acc: { [key: string]: boolean }, key: string) => {
  if (key.includes('SLOT_')) {
    acc[key as string] = process.env[key as string] === 'true';
  }

  return acc;
}, {}) as ISlotFeaturesConfig;
