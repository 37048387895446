import { useQuery } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Toggle } from '@phoenix7dev/shared-components';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import {
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsSoundOn,
  setIsTurboSpin,
  setSkipIntroScreen,
  setSoundValue,
} from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { slotConfigGql } from '../../gql/query';
import { Logic } from '../../logic';
import { eventManager } from '../../slotMachine/config';
import { handleChangeRestriction } from '../../utils';

import type { ISettingsProps } from './d';
import styles from './settings.module.scss';

const Setting: React.FC<ISettingsProps> = (props) => {
  const { t } = useTranslation();
  const { className = '', handleClose = () => {} } = props;
  const { data } = useQuery<IConfig>(slotConfigGql);

  const { isSoundOn, isMobile, isEnabledSpaceSpin, isTurboSpin, isLeftHandMode } = data!;

  const handleClosePopup = (): void => {
    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen) && !AudioApi.isPlaying(ISongs.SFX_UI_SpinStart)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    handleClose();
  };

  const handleSound = () => {
    AudioApi.setSoundState(!isSoundOn);
    setIsSoundOn(!isSoundOn);
    setSoundValue(!isSoundOn ? 1 : 0);

    if (!isSoundOn) {
      AudioApi.setVolume(1);
    }

    if (AudioApi.isRestricted) {
      if (!AudioApi.isInitialized) {
        AudioApi.hideRestrictionModal = true;

        eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
      }

      AudioApi.changeRestriction(
        false,
        [],
        () => {},
        () => handleChangeRestriction(Logic.the.controller.gameMode),
      );
    }

    AudioApi.play({ type: ISongs.SFX_UI_General });
  };
  return (
    <div className={`${styles['settings']} ${className}`}>
      <div className={styles['settings__row']}>
        <Toggle className="toggle" checked={!!isSoundOn} id="sound_on" onChange={handleSound} />
        <div className={styles['label']}>{t('sound')}</div>
      </div>
      <div className={styles['settings__row']}>
        <Toggle
          className="toggle"
          checked={setSkipIntroScreen()}
          id="skip_intro"
          onChange={() => {
            setSkipIntroScreen(!setSkipIntroScreen());
            AudioApi.play({ type: ISongs.SFX_UI_General });
          }}
        />
        <div className={styles['label']}>{t('skipScreen')}</div>
      </div>
      <div className={styles['settings__row']}>
        <Toggle
          className="toggle"
          checked={setIsMiniPayTable()}
          id="mini_paytable"
          onChange={() => {
            setIsMiniPayTable(!setIsMiniPayTable());
            AudioApi.play({ type: ISongs.SFX_UI_General });
          }}
        />
        <div className={styles['label']}>{t('miniPaytable')}</div>
      </div>
      {!isMobile && (
        <div className={styles['settings__row']}>
          <Toggle
            className="toggle"
            checked={!!isEnabledSpaceSpin}
            id="space_control"
            onChange={() => {
              setIsEnabledSpaceSpin(!isEnabledSpaceSpin);
              AudioApi.play({ type: ISongs.SFX_UI_General });
            }}
          />
          <div className={styles['label']}>{t('spaceSpin')}</div>
        </div>
      )}
      <div className={styles['settings__row']}>
        <Toggle
          className="toggle"
          checked={!!isTurboSpin}
          id="turbo_spin"
          onChange={() => {
            setIsTurboSpin(!isTurboSpin);
            AudioApi.play({ type: ISongs.SFX_UI_General });
          }}
        />
        <div className={styles['label']}>{t('turboSpin')}</div>
      </div>
      {isMobile && (
        <div className={styles['settings__row']}>
          <Toggle
            className="toggle"
            checked={!!isLeftHandMode}
            id="left_hand"
            onChange={() => {
              setIsLeftHandMode(!isLeftHandMode);
              AudioApi.play({ type: ISongs.SFX_UI_General });
            }}
          />
          <div className={styles['label']}>{t('leftHandMode')}</div>
        </div>
      )}
      <Button intent="close" className="popup__close" onClick={handleClosePopup} />
    </div>
  );
};

export default Setting;
