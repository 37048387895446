export const gameRules = [
  { key: 'infoGameRules1_1', buyFeatureOnly: false },
  { key: 'infoGameRules1_2', buyFeatureOnly: false },
  { key: 'infoGameRules1_3', buyFeatureOnly: false },
  { key: 'infoGameRules1_4', buyFeatureOnly: false },
  { key: 'infoGameRules1_5', buyFeatureOnly: false },
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_7', buyFeatureOnly: false },
  { key: 'infoGameRules1_8', buyFeatureOnly: false },
  { key: 'infoGameRules1_9', buyFeatureOnly: false },
  { key: 'infoGameRules1_10', buyFeatureOnly: false },
  { key: 'infoGameRules1_11', buyFeatureOnly: false },
  { key: 'infoGameRules1_12', buyFeatureOnly: false },
  { key: 'infoGameRules1_13', buyFeatureOnly: false },
  { key: 'infoGameRules1_14', buyFeatureOnly: false },
  { key: 'infoGameRules1_15', buyFeatureOnly: false },
];
