import { Sprite, Text, Texture } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { setCurrency } from '../../../../gql/cache';
import { ResourceTypes } from '../../../../resources.d';
import { showCurrency } from '../../../../utils';
import { TextField } from '../../../components/TextField';
import { buyFeatureBetAmount } from '../buyFeaturePopup.model';

export class Bet extends Sprite {
  public override name = 'Bet';
  private amount = this.getAmount();
  constructor(private initAmount = 0) {
    super(Texture.from(ResourceTypes.buyFeaturePopupBackplate));
    this.width = 184;
    this.height = 56;
    this.anchor.set(0.5, 0.5);
    this.addChild(this.amount);
    this.update(initAmount);
  }
  public update(amount: number): void {
    const currency = setCurrency();
    this.amount.text = formatNumber({ currency: currency, value: amount, showCurrency: showCurrency(currency) });
  }
  private getAmount(): Text {
    const textField = new TextField('0', 300, 100, buyFeatureBetAmount);
    textField.text.anchor.set(0.5, 0.5);

    return textField.getText();
  }
}
