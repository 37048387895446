import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { ModalOpeningTypes } from '../../global.d';
import { setIsModalOpeningInProgress, setIsOpenBetSettingsModal } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { slotConfigGql } from '../../gql/query';

import BetSettings from '.';

const BetSettingsMenu: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const { data: configData } = useQuery<IConfig>(slotConfigGql);
  const { isOpenBetSettingsModal } = configData!;

  useEffect(() => {
    if (isOpenBetSettingsModal) {
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenBetSettingsModal);
  }, [isOpenBetSettingsModal]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsModalOpeningInProgress() &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.BET_SETTINGS &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.BUY_FEATURE_POPUP &&
      !directClickToCloseIcon
    )
      return;
    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      }
    }, 0);
    setIsOpenBetSettingsModal(false);
  };

  return (
    <Popup id="popup" className="popup" showNow={showPopup} setShowNow={() => handleClose()}>
      <div className="popup__title">{t('betSettings')}</div>
      <Button intent="close" className="popup__close" onClick={() => handleClose(true)} />
      <BetSettings />
    </Popup>
  );
};

export default BetSettingsMenu;
