interface Feature {
  title: string;
  description: string;
  isImage?: boolean;
}
export const features: Feature[] = [
  {
    title: 'info_awakening_meter',
    description: 'info_awakening_meter_description',
  },
  {
    title: 'info_free_spins',
    description: 'info_free_spins_description',
  },
  {
    title: 'info_buy_feature',
    description: 'info_buy_feature_description',
    isImage: true,
  },
];
