import { Container, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../../config';
import { setBetAmount, setCoinAmount } from '../../../../gql/cache';
import { getBetsSetting } from '../../../../gql/fromFragment';
import { ResourceTypes } from '../../../../resources.d';
import { normalizeCoins } from '../../../../utils';
import { SpriteButton, SpriteButtonState } from '../../../components/SpriteButton';

import { Bet } from './bet';

export class BetControl extends Container {
  public override name = 'BetControl';
  private betSettings = getBetsSetting();
  private minusBtn = this.getMinusButton();
  private plusBtn = this.getPlusBtn();
  private betAmounts = this.betSettings.bets.map((bet) => bet * this.betSettings.coinAmountMultiplier);
  private value = setBetAmount();
  private bet = new Bet(normalizeCoins(this.value));

  constructor(private onChange: (betAmount: number) => void) {
    super();
    this.minusBtn.x = this.minusBtn.width / 2;
    this.bet.x = this.minusBtn.width + 16 + this.bet.width / 2;
    this.plusBtn.x = this.bet.x + this.bet.width / 2 + 16 + this.plusBtn.width / 2;

    this.addChild(this.minusBtn, this.bet, this.plusBtn);
  }
  private setBetAmount(value: number): void {
    normalizeCoins(value) <= this.betSettings.minBetCount ? this.minusBtn.disable() : this.minusBtn.enable();
    normalizeCoins(value) >= this.betSettings.maxBetCount ? this.plusBtn.disable() : this.plusBtn.enable();

    this.bet.update(normalizeCoins(value));
    this.value = value;
    setCoinAmount(value / this.betSettings.coinAmountMultiplier);
    setBetAmount(value);
    this.onChange(value);

    AudioApi.play({ type: ISongs.SFX_UI_BetChange });
  }
  private getMinusButton(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureMinusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.setBetAmount(this.getPrevValue()),
      onTouchStart: () => this.setBetAmount(this.getPrevValue()),
    });

    minusBtn.height = 56;
    minusBtn.width = 56;

    if (normalizeCoins(setBetAmount()) <= this.betSettings.minBetCount) {
      minusBtn.disable();
    }

    return minusBtn;
  }
  private getPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePlusBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.setBetAmount(this.getNextValue()),
      onTouchStart: () => this.setBetAmount(this.getNextValue()),
    });

    plusBtn.height = 56;
    plusBtn.width = 56;

    if (normalizeCoins(setBetAmount()) >= this.betSettings.maxBetCount) {
      plusBtn.disable();
    }

    return plusBtn;
  }

  private getPrevValue(): number {
    const currentIndex = this.betAmounts.findIndex((value) => value === this.value);

    return this.betAmounts[currentIndex - 1] ?? 0;
  }
  private getNextValue(): number {
    const currentIndex = this.betAmounts.findIndex((value) => value === this.value);

    return this.betAmounts[currentIndex + 1] ?? Infinity;
  }
}
