import type { Group } from '@pixi/layers';
import _ from 'lodash';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId, mappedAudioSprites } from '../../../config';
import { EventTypes, GameMode } from '../../../global.d';
import { setBetResult, setCurrentIsTurboSpin, setSlotConfig } from '../../../gql/cache';
import { Logic } from '../../../logic';
import { getBetResult, is4SlotsReel, normalizePosition } from '../../../utils';
import { getSpinResult } from '../../../utils/getSpinResult';
import type Animation from '../../animations/animation';
import AnimationChain from '../../animations/animationChain';
import { TweenProperties } from '../../animations/d';
import { BaseAnimation } from '../../animations/reel/baseAnimation';
import type { ReelAnimation } from '../../animations/reel/reelAnimation';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import {
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  REELS_AMOUNT,
  ReelState,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../../config';
import { Dragon } from '../../dragon/dragon';
import { BaseDragonMovements } from '../../dragon/dragon.model';
import Slot from '../slot';

import type { Reel } from './reel';

export class BaseReel extends ViewContainer implements Reel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public slots: Slot[] = [];

  public size: number;

  public isPlaySoundOnStop: boolean;

  public animation: ReelAnimation | null = null;

  public stopPosition: number;

  private isAnticipated = false;

  private isForceStopped = false;

  public anticipationAnimation: Animation | undefined;

  constructor(id: number, data: SlotId[], startPosition: number, slotGroup: Group) {
    super();
    this.name = BaseReel.name;
    this.id = id;
    this.data = data;
    this.size = this.data.length;
    this.state = ReelState.IDLE;
    this.isPlaySoundOnStop = true;
    this.sortableChildren = true;
    this.stopPosition = startPosition;
    this.width = SLOT_WIDTH;
    this.x = SLOT_WIDTH * 0.5 + (SLOT_WIDTH - SLOT_WIDTH / 4) * id;
    this.y = -SLOT_HEIGHT;

    this.createSlots(slotGroup);

    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      if (!this.anticipationAnimation) {
        this.isForceStopped = true;
      }
    });
  }

  public changeReelData(data: SlotId[], slotGroup: Group, position: number): void {
    this.stopPosition = position;
    this.data = data;
    this.size = this.data.length;
    this.slots = [];
    this.removeChildren();
    this.createSlots(slotGroup);
  }

  public changeData(data: SlotId[], slotGroup: Group): void {
    this.data = data;
    this.size = this.data.length;
    this.slots = [];
    this.removeChildren();
    this.createSlots(slotGroup);
    this.toggleBlurSlots(true);
  }

  public createSlots(slotGroup: Group): void {
    for (let i = 0; i < this.data.length; i++) {
      const slot = new Slot(i, this.data[i as number] as SlotId, this.id);
      this.slots.push(slot);
      slot.parentGroup = slotGroup;
      slot.y = this.getSlotY(slot);

      this.addChild(slot);
    }
  }

  public getSlotY(slot: Slot): number {
    let position = -this.stopPosition;
    while (position < 0) {
      position += this.data.length;
    }
    return (
      SLOT_HEIGHT * (((position + slot.id + 2) % this.data.length) + 0.5) -
      (is4SlotsReel(this.id) ? SLOT_HEIGHT / 2 : 0)
    );
  }

  protected override onModeChange(): void {}

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(false);
  }

  private onReelStop(): void {
    const spinResult = getSpinResult({
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
      reelSet: getBetResult(setBetResult()).bet.reelSet,
      icons: setSlotConfig().icons,
    });
    const reelResult = [spinResult[this.id], spinResult[this.id + 5], spinResult[this.id + 5 * 2]];
    const isScatterOnReelMap = [];

    for (let i = 0; i < REELS_AMOUNT; i++) {
      isScatterOnReelMap.push(
        [spinResult[i as number], spinResult[i + 5], spinResult[i + 5 * 2]].some((value) => value?.id === SlotId.SC),
      );
    }

    this.anticipationAnimation?.skip();
    this.anticipationAnimation = undefined;
    this.isForceStopped = false;

    if (this.isAnticipated) {
      AudioApi.stop({ type: ISongs.LongSpin });
      this.isAnticipated = false;
    }

    if (reelResult.some((value) => value?.id === SlotId.SC)) {
      // Scatter stop sound logic
      if (this.id === 0) {
        AudioApi.play({ type: ISongs.ScatterLanding1 });
      }

      if (this.id === 2 && isScatterOnReelMap[0]) {
        AudioApi.play({ type: ISongs.ScatterLanding2 });
      } else {
        AudioApi.play({ type: ISongs.SFX_UI_SpinStop });
      }

      if (this.id === 4 && isScatterOnReelMap[0] && isScatterOnReelMap[2]) {
        AudioApi.play({ type: ISongs.ScatterLanding3 });
      } else {
        AudioApi.play({ type: ISongs.SFX_UI_SpinStop });
      }
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_SpinStop });
    }
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.APPEARING) {
      this.onReelStop();
    }
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(true);
  }

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.DISAPPEARING) {
      this.onReelRolling(previousState, ReelState.DISAPPEARING);
    }
    if (newState === ReelState.WAITING) {
      this.onReelStarting(previousState, ReelState.WAITING);
    }
    if (newState === ReelState.APPEARING) {
      this.onReelEnding(previousState, ReelState.APPEARING);
    }
  }

  public createSpinAnimation(): ReelAnimation {
    const onChange = () => {
      this.slots.forEach((slot) => {
        slot.y = this.getSlotY(slot);
      });
    };
    const isTurboSpin = setCurrentIsTurboSpin() && Logic.the.controller.gameMode === GameMode.BASE_GAME;

    const disappearingAnimation = new AnimationChain();
    disappearingAnimation.appendAnimation(Tween.createDelayAnimation(100 * this.id));
    const disappearingTarget = normalizePosition(this.size, this.stopPosition - SLOTS_PER_REEL_AMOUNT);
    const disappearingBegin = this.stopPosition;
    disappearingAnimation.appendAnimation(
      new Tween({
        object: this,
        property: TweenProperties.STOP_POSITION,
        propertyBeginValue: disappearingBegin,
        target: disappearingBegin - SLOTS_PER_REEL_AMOUNT,
        duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
        delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
        easing: BASE_REEL_STARTING_FORMULA,
      }),
    );
    disappearingAnimation.addOnStart(() => {
      this.changeState(ReelState.DISAPPEARING);
    });
    disappearingAnimation.addOnChange(onChange);
    const speed = 25;
    const duration = 12000;
    const waitingTarget = disappearingTarget - (speed * duration) / 1000;
    const waitingAnimation = new Tween({
      object: this,
      property: TweenProperties.STOP_POSITION,
      propertyBeginValue: disappearingTarget,
      target: waitingTarget,
      duration,
    });
    waitingAnimation.addOnStart(() => {
      this.changeState(ReelState.WAITING);
    });
    waitingAnimation.addOnChange(onChange);
    this.animation = new BaseAnimation({
      disappearingAnimation,
      waitingAnimation,
    });
    return this.animation;
  }

  private toggleBlurSlots(enabled: boolean): void {
    this.slots.forEach((slot) => {
      if (slot.slotId !== SlotId.SC) slot.toggleBlur(enabled);
    });
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.slot.tint = 0xffffff;
    });
    this.isForceStopped = false;
  }

  private onAnticipationStart(id: number): void {
    _.forEach(this.slots, (slot) => {
      if (slot.slotId !== SlotId.SC) {
        slot.slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
    if (this.id === id && !this.anticipationAnimation && !this.isForceStopped) {
      this.anticipationAnimation = this.createAnticipationBackgroundAnimation();
      this.anticipationAnimation.start();

      setTimeout(() => AudioApi.play({ type: ISongs.LongSpin }), mappedAudioSprites[ISongs.KidDragonAttack].duration);
      this.isAnticipated = true;
    }
  }

  private createAnticipationBackgroundAnimation(): Animation {
    const dragonAttackAnimation = Dragon.the.setAnimation(BaseDragonMovements.attack);
    const dummy = Tween.createDelayAnimation(dragonAttackAnimation.animationEnd);

    AudioApi.play({ type: ISongs.KidDragonAttack });

    return dummy;
  }
}
