import { Sprite, Texture } from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import { ViewContainer } from '../../components/ViewContainer';

class ReelsFrame extends ViewContainer {
  private background: Sprite;

  constructor() {
    super();
    this.background = this.initBackground();
    this.addChild(this.background);
    this.x = 110;
    this.y = -100;
  }

  initBackground(): Sprite {
    const texture = Texture.from(ResourceTypes.reelFrame);
    const sprite = new Sprite(texture);
    return sprite;
  }
}

export default ReelsFrame;
