import i18n from 'i18next';
import { Container, Sprite, Text, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setCurrency } from '../../../gql/cache';
import { ResourceTypes } from '../../../resources.d';
import { showCurrency } from '../../../utils';
import { setSizesByFormFactor } from '../../awakening-meter/awakening-meter.model';
import { SpriteButton, SpriteButtonState } from '../../components/SpriteButton';
import { TextField } from '../../components/TextField';
import { eventManager } from '../../config';
import { ModalService } from '../../popups/ModalService';

import { buyFeaturePopupSizes, buyFeatureWhiteText, buyFeatureYellowText } from './buyFeaturePopup.model';

export class BuyFeatureWrapper extends Container {
  public override name = 'BuyFeaturePopupWrapper';
  private body = this.getBody();
  public okBtn = this.getOkBtn();
  private cancelBtn = this.geCancelBtn();
  private totalCost = this.getTotalCost();

  constructor(private apply: () => void, private cancel?: () => void) {
    super();
    this.addChild(this.body);

    this.body.addChild(this.getTotalCostTitle(), this.totalCost, this.getFooterContainer());

    setSizesByFormFactor(this.body, buyFeaturePopupSizes);
    eventManager.on(EventTypes.RESIZE, () => {
      setSizesByFormFactor(this.body, buyFeaturePopupSizes);
    });
  }

  public add(...components: Container[]): void {
    this.body.addChild(...components);
    components.forEach((component) => this.alignToCenterBody(component));
  }
  public changeTotalCost(betAmount: number): void {
    const currency = setCurrency();
    this.totalCost.text = formatNumber({ currency: currency, value: betAmount, showCurrency: showCurrency(currency) });
    this.alignToCenterBody(this.totalCost);
  }

  private getBody(): Container {
    const background = new Sprite(Texture.from(ResourceTypes.buyFeaturePopup));
    const body = new Container();
    background.width = 656;
    background.height = 560;
    body.addChild(background);

    return body;
  }
  private getTotalCost(): Text {
    const textField = new TextField(i18n.t<string>(''), 163, 52, buyFeatureWhiteText);
    this.alignToCenterBody(textField.text);
    textField.text.y = 335;

    return textField.getText();
  }
  private getFooterContainer(): Container {
    const footerContainer = new Container();

    footerContainer.addChild(this.cancelBtn, this.okBtn);
    this.cancelBtn.x = this.cancelBtn.width / 2;
    this.okBtn.x = this.cancelBtn.width + 56 + this.okBtn.width / 2;

    this.alignToCenterBody(footerContainer);
    footerContainer.addChild(this.cancelBtn, this.okBtn);
    footerContainer.y = 400 + this.cancelBtn.height / 2;

    return footerContainer;
  }
  private getTotalCostTitle(): Container {
    const textField = new TextField(i18n.t<string>('buyFeatureTotalCost'), 123, 38, buyFeatureYellowText);
    this.alignToCenterBody(textField.text);
    textField.text.y = 298;

    return textField.getText();
  }
  private alignToCenterBody(element: Container): void {
    element.x = (this.body.width - element.width * this.body.scale.x) / 2 / this.body.scale.x;
  }
  private getOkBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureOkBtnDisable),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.apply(),
      onTouchStart: () => this.apply(),
    });
    plusBtn.height = 48;
    plusBtn.width = 96;

    return plusBtn;
  }
  private geCancelBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureXBtnDisabled),
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => this.hide(),
      onTouchStart: () => this.hide(),
    });

    plusBtn.height = 48;
    plusBtn.width = 96;

    return plusBtn;
  }
  private hide(): void {
    if (this.cancel) {
      return this.cancel();
    }
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    ModalService.the.hide(true);
  }
}
