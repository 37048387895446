import { Group, Layer } from '@pixi/layers';
import i18n from 'i18next';
import type { Application } from 'pixi.js';

import type { SlotId } from '../config/config';
import { EventTypes, UserBonus } from '../global.d';
import {
  setBrokenGame,
  setCurrentReelSetId,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setLastSpinData,
  setReelSets,
  setStressful,
  setUserLastBetResult,
} from '../gql/cache';
import { Logic } from '../logic';
import { States } from '../logic/config';
import { SLOT_FEATURES_CONFIG } from '../slotFeaturesConfig/slotFeaturesConfig';
import { getLayerOrderByName } from '../utils';

import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import { BigWinContainer } from './bigWinPresentation/bigWinContainer';
import BottomContainer from './bottomContainer/bottomContainer';
import type { ViewContainer } from './components/ViewContainer';
import { PopupTypes, REELS_AMOUNT, eventManager } from './config';
import AutoplayBtn from './controlButtons/autoplayBtn';
import BetBtn from './controlButtons/betBtn';
import MenuBtn from './controlButtons/menuBtn';
import SoundBtn from './controlButtons/soundBtn';
import SpinBtn from './controlButtons/spinBtn';
import TurboSpinBtn from './controlButtons/turboSpinBtn';
import type { ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import { Logo } from './logo/logo';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import PaylinesContainer from './paylines/paylinesContainer';
import Phoenix from './phoenix/phoenix';
import { ModalService } from './popups/ModalService';
import { PopupController } from './popups/PopupController';
import { FreeSpinsEndPopup } from './popups/freeSpinsPopup/freeSpinsEndPopup';
import { FreeSpinsPopup } from './popups/freeSpinsPopup/freeSpinsPopup';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import BaseReelsContainer from './reels/reelsContainer/baseReelsContainer';
import type { ReelsContainer } from './reels/reelsContainer/reelsContainer';
import type Slot from './reels/slot';
import SafeArea from './safeArea/safeArea';
import TintContainer from './tint/tintContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import { BaseWinSlotsPresentation } from './winAnimations/winSlotsPresentation/baseWinSlotsPresentation';

class SlotMachine {
  public isStopped = false;

  public static initSlotMachine = (slotData: ISlotData): void => {
    SlotMachine.slotMachine = new SlotMachine(Logic.the.application, slotData);
  };

  public static the(): SlotMachine {
    return SlotMachine.slotMachine;
  }

  private static slotMachine: SlotMachine;

  private application: Application;

  private reelsContainer!: BaseReelsContainer;

  private miniPayTableContainer!: MiniPayTableContainer;

  public layer: Layer;

  public layersGroup: Group;

  public bottomContainer!: BottomContainer;

  public gameView!: GameView;

  public background!: Background;

  private constructor(application: Application, slotConfig: ISlotData) {
    this.application = application;
    this.application.stage.sortableChildren = true;
    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = getLayerOrderByName(layer.name);
    });
    this.layer = new Layer(this.layersGroup);
    this.application.stage.addChild(this.layer);
    this.initSlotMachineListeners();
    this.buildSlotMachine(slotConfig);
    ModalService.the.registerModal(this.application.stage);
  }

  private initSlotMachineListeners(): void {
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      if (!setBrokenGame()) eventManager.emit(EventTypes.GAME_READY);
    });
    eventManager.addListener(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE, this.setCurrentResultMiniPayTable.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, SlotMachine.handleError);
  }

  private buildSlotMachine(slotConfig: ISlotData): void {
    const isLastBetPresent = setUserLastBetResult().id;
    setReelSets(slotConfig.reels);
    const startPosition = isLastBetPresent
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;
    setLastSpinData({
      layout: [],
      reelPositions: startPosition,
    });

    const reelSet = isLastBetPresent
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => {
          return (reelSet.type as unknown as string) === 'DEFAULT';
        })!;
    this.background = new Background();
    this.background.name = 'Background';
    this.background.parentGroup = this.layersGroup;
    this.reelsContainer = this.getReelsContainer(reelSet.layout, startPosition);
    this.miniPayTableContainer = new MiniPayTableContainer(
      slotConfig.icons,
      this.reelsContainer.getCurrentSpinResult(),
      this.getSlotById.bind(this),
    );
    const bigWinContainer = new BigWinContainer();
    bigWinContainer.name = 'BigWinContainer';
    bigWinContainer.parentGroup = this.layersGroup;
    this.gameView = new GameView({
      winSlotsContainer: this.getWinSlotsContainer(),
      paylinesContainer: new PaylinesContainer(slotConfig.lines),
      reelsBackgroundContainer: new ReelsBackgroundContainer(),
      reelsContainer: this.reelsContainer as unknown as ReelsContainer & ViewContainer,
      tintContainer: new TintContainer(),
      bigWinContainer,
      winCountUpMessage: new WinCountUpMessage(),
      miniPayTableContainer: this.miniPayTableContainer,
    });
    const freeSpinsPopup = new FreeSpinsPopup();
    const freeSpinsEndPopup = new FreeSpinsEndPopup();
    const menuBtn = new MenuBtn();
    const soundBtn = new SoundBtn();
    const turboSpinBtn = new TurboSpinBtn();
    const spinBtn = new SpinBtn();
    const betBtn = new BetBtn();
    const autoplayBtn = new AutoplayBtn();
    const safeArea = new SafeArea();
    const phoenix = new Phoenix();

    safeArea.name = 'SafeArea';
    safeArea.parentGroup = this.layersGroup;

    setCurrentReelSetId(reelSet.id);

    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      this.skipWinAnimation();
    });
    this.gameView.on('touchstart', () => {
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      this.skipWinAnimation();
    });

    if (SLOT_FEATURES_CONFIG.REACT_APP_SLOT_IS_BUY_FEATURE_ENABLED) {
      // this.gameView.addChild(new BuyFeatureBtn());
      // this.gameView.addChild(this.buildBuyFeaturePopupsContainer());
    }

    PopupController.the.registerPopup(PopupTypes.FREE_SPINS, freeSpinsPopup);
    PopupController.the.registerPopup(PopupTypes.FREE_SPINS_END, freeSpinsEndPopup);

    safeArea.addChild(this.gameView);
    const backdrop = new Backdrop(EventTypes.OPEN_POPUP_BG, EventTypes.CLOSE_POPUP_BG);
    backdrop.name = 'Backdrop';
    backdrop.parentGroup = this.layersGroup;
    this.bottomContainer = new BottomContainer();
    this.application.stage.addChild(
      this.background,
      backdrop,
      safeArea,
      freeSpinsEndPopup,
      freeSpinsPopup,
      this.bottomContainer,
      new FadeArea(),
      menuBtn,
      soundBtn,
      turboSpinBtn,
      spinBtn,
      betBtn,
      autoplayBtn,
      phoenix,
      new Logo(),
    );
  }

  private skipWinAnimation(): void {
    if (Logic.the.state.name === States.AFTER_WIN || Logic.the.state.name === States.IDLE) {
      Logic.the.skipWinAnimation();
    }
  }

  private getReelsContainer(reelSetLayout: SlotId[][], startPosition: number[]) {
    return new BaseReelsContainer(reelSetLayout, startPosition);
  }

  private getWinSlotsContainer() {
    return new BaseWinSlotsPresentation();
  }

  private static handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setIsRevokeThrowingError(true);
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('errors.UNKNOWN.UNKNOWN'),
      });
    }
  }

  public onBrokenGame(bonus: UserBonus): void {
    eventManager.emit(EventTypes.BROKEN_GAME, bonus);
    eventManager.emit(EventTypes.GAME_READY);
  }

  public spinSpinAnimation(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_ANIMATION);
    eventManager.emit(EventTypes.START_SPIN_ANIMATION);
  }

  public getSlotAt(x: number, y: number): Slot | undefined {
    return this.reelsContainer.reels[x as number]!['slots'][y as number];
  }

  public getSlotById(id: number): Slot | undefined {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public setCurrentResultMiniPayTable(): void {
    this.miniPayTableContainer.updateMiniPaytable(this.reelsContainer.getCurrentSpinResult());
  }
}

export default SlotMachine;
