import { utils } from 'pixi.js';

import { SlotId } from '../../config';

export * from './textStyles';

class CustomEmitter extends utils.EventEmitter {
  override on(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.on(event, fn, context);
  }

  override once(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.once(event, fn, context);
  }

  override addListener(event: string | symbol, fn: utils.EventEmitter.ListenerFn, context?: unknown): this {
    return super.addListener(event, fn, context);
  }

  override emit(event: string | symbol, ...args: unknown[]): boolean {
    return super.emit(event, ...args);
  }
}

export const eventManager = new CustomEmitter();

(window as unknown as { eventManager: CustomEmitter }).eventManager = eventManager;

export enum PopupTypes {
  BUY_FEATURE,
  BUY_FEATURE_CONFIRMATION,
  FREE_SPINS,
  FREE_SPINS_END,
  SPIN_AND_GRAB_START,
  SPIN_AND_GRAB_END,
}

// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = true;
export const APPLICATION_FPS = 60;

// RESET ANIMATION
export const RESET_ANIMATION_BASE_DURATION = 300;
export const RESET_ANIMATION_TURBO_DURATION = 200;

// SLOT SETTINGS
export const SLOT_SCALE = 1;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;

// BIG WIN SETTINGS
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;

// MEGA WIN SETTINGS
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 5;
export const GRAB_AND_SPIN_REELS_AMOUNT = 15;
export const SLOTS_PER_REEL_AMOUNT = 3;
export const SPIN_AND_GRAB_SLOTS_PER_REEL_AMOUNT = 1;
export const REEL_WIDTH = 225;
export const SLOT_WIDTH = 225;
export const SLOT_HEIGHT = 196;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const GAME_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;
export const FRAME_WIDTH = 1314;

// COUNT UP
export const COUNT_UP_MESSAGE_X = SLOTS_CONTAINER_WIDTH / 2;
export const COUNT_UP_MESSAGE_Y = SLOTS_CONTAINER_HEIGHT + 215;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.SC];
export const ANTICIPATION_SYMBOLS_AMOUNT = [2, 4];
export const ANTICIPATION_DURATION = 2500;
export const ANTICIPATION_DURATION_TURBOSPIN = 800;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 15;
export const ANTICIPATION_SLOTS_TINT = 0xaaaaaa;

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 36;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 36;

// REELS SETTINGS
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_DISAPPEARING_DURATION = 300;
export const TURBO_REEL_STARTING_DURATION = 250;
export const TURBO_DISAPPEARING_DURATION = 150;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
export const BASE_APPEARING_DURATION = 300;
export const TURBO_APPEARING_DURATION = 150;
export enum ReelState {
  IDLE,
  DISAPPEARING,
  WAITING,
  APPEARING,
}
// NUMBER FORMAT
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// SPIN ANIMATION SETTINGS
export const DELAY_BETWEEN_REELS = 120;
export const BASE_REEL_STARTING_FORMULA = (t: number): number => 3 * t * t * (1.4 * t - 0.4);
export const BASE_REEL_ROLLING_FORMULA = (t: number): number => -0.592691 + 1.77055 * t;
export const BASE_REEL_ENDING_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.1;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH + SLOT_WIDTH / 2 - 10;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.65;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 3000;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// BUY FEATURE BTN
export const FEATURE_BTN_CONTAINER_POSITION_X = -190;
export const FEATURE_BTN_WIDTH = 200;
export const FEATURE_BTN_HEIGHT = 200;

// BUY FEATURE POPUP
export const FEATURE_POPUP_WIDTH = 1067;
export const MOBILE_FEATURE_POPUP_WIDTH = 1300;
export const FEATURE_POPUP_HEIGHT = 928;
export const MOBILE_FEATURE_POPUP_HEIGHT = 1100;
export const FEATURE_POPUP_POSITION_X = -70;
export const MOBILE_FEATURE_POPUP_POSITION_X = -60;
export const FEATURE_POPUP_POSITION_Y = 0;
export const MOBILE_FEATURE_POPUP_TITLE_POSITION_X = 650;

// BUY FEATURE CANCEL
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 900;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = 350;

// SPIN AND GRAB
export const INITIAL_SPIN_AND_GRAB_ROUNDS_NUMBER = 3;
