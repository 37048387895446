export enum ResourceTypes {
  buyButtonDefault = 'buyButtonDefault',
  buyButtonDisabled = 'buyButtonDisabled',
  buyButtonHover = 'buyButtonHover',
  buyButtonPressed = 'buyButtonPressed',
  buyFeaturePopupBackplate = 'buyFeaturePopupBackplate',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopupRage = 'buyFeaturePopupRage',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureDisable = 'buyFeatureDisable',
  buyFeatureHover = 'buyFeatureHover',
  buyFeaturePressed = 'buyFeaturePressed',
  buyFeature = 'buyFeature',
  freeSpinsDefault = 'freeSpinsDefault',
  freeSpinsDisabled = 'freeSpinsDisabled',
  freeSpinsHover = 'freeSpinsHover',
  freeSpinsPressed = 'freeSpinsPressed',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  subTitleBackplate = 'subTitleBackplate',
  buyFeatureXBtnDisabled = 'buyFeatureXBtnDisabled',
  buyFeatureXBtnHover = 'buyFeatureXBtnHover',
  buyFeatureXBtnPressed = 'buyFeatureXBtnPressed',
  buyFeatureXBtn = 'buyFeatureXBtn',
  counterFreeSpins = 'counterFreeSpins',
  frameFreeSpins = 'frameFreeSpins',
  frameSpinAndGrab = 'frameSpinAndGrab',
  frame = 'frame',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  logo = 'logo',
  reel5Background = 'reel5Background',
  reelBackground = 'reelBackground',
  reelFrame = 'reelFrame',
  slotBgFreeSpins = 'slotBgFreeSpins',
  slotBg = 'slotBg',
  spinAndGrabRespins = 'spinAndGrabRespins',
  a = 'a',
  b = 'b',
  c = 'c',
  d = 'd',
  e = 'e',
  f = 'f',
  g = 'g',
  h = 'h',
  i = 'i',
  sc = 'sc',
  wl = 'wl',
  wlA = 'wlA',
  wlB = 'wlB',
  wlC = 'wlC',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  gameLogo = 'gameLogo',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
}
