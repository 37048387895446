import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { Environments } from '@phoenix7dev/audio-api/dist/d';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import { LOADER_MAPPED_SYMBOLS, LOADER_TEXTURES, audioSprite, audioSpriteVolume } from '../../config';
import { BonusStatus, EventTypes, GameMode, IAuthInput, IBonus, IUserBalance, UserBonus } from '../../global.d';
import {
  setAvailableBonuses,
  setBetAmount,
  setBonuses,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setProgress,
  setSkipIntroScreen,
  setSlotConfig,
  setUserBalance,
  setUserLastBetResult,
  setUserLastBonusBet,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import type { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  getBonuses,
  getSlotGql,
  getSlotLoadProgressInfoGql,
  getUserBonuses,
  getUserGql,
  slotConfigGql,
  slotHistoryGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import type { ISlotData } from '../../slotMachine/d';
import {
  findSubstituteCoinAmount,
  isBuyFeatureEnabled,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  wait,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const getCoinValue = (slotConfig: ISlotData) => {
  if (setBrokenGame()) {
    return setCurrentBonus().coinValue;
  }
  return slotConfig.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
};

const getCoinAmount = (slotConfig: ISlotData) => {
  const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
  let coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slotConfig.clientSettings.coinAmounts.default);

  if (setBrokenGame()) {
    coinAmount = setCurrentBonus().coinAmount;
  }
  return coinAmount;
};

const getUserBalanceFn = async () => {
  const userBalance = await client.query<{ user: IUserBalance }>({
    query: getUserGql,
    fetchPolicy: 'network-only',
  });
  setUserBalance(userBalance.data.user);
  setCurrency(userBalance.data.user.balance.currency);
};

const getLastBetFn = async () => {
  const betsData = await client.query<{ bets: ISlotHistoryData }>({
    query: slotHistoryGql,
    variables: {
      input: { last: 1, filter: { slotId: setSlotConfig().id } },
    },
    fetchPolicy: 'network-only',
  });
  const lastBet = betsData.data.bets.edges[0];

  if (lastBet) {
    if (lastBet.node.userBonus?.betId) {
      setUserLastBonusBet(lastBet.node);
    }
    setUserLastBetResult(lastBet.node);
  }
};

const getPurchasableBonusesFn = async () => {
  const bonusData = await client.query<{ bonuses: IBonus[] }>({
    query: getBonuses,
    variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
    fetchPolicy: 'network-only',
  });
  setBonuses(bonusData.data.bonuses);
};

const getSlotDataFn = async () => {
  const slotData = await client.query<{ slot: ISlotData }>({
    query: getSlotGql,
    variables: { input: { id: setSlotConfig().id } },
    fetchPolicy: 'network-only',
  });
  const { slot } = slotData.data;
  const slotConfig = {
    clientSettings: slot.clientSettings,
    settings: slot.settings,
    previewImage: slot.previewImage,
    icons: slot.icons,
    reels: slot.reels,
    lineSets: slot.lineSets,
    lines: slot.lines,
    isBuyFeatureEnabled: isBuyFeatureEnabled(slot.clientSettings.features),
  };
  const coinValue = getCoinValue(slot);
  const coinAmount = getCoinAmount(slot);

  setSlotConfig({
    ...setSlotConfig(),
    ...slotConfig,
  });
  setGameMode(GameMode.BASE_GAME);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setWinAmount(setUserLastBetResult().result.winCoinAmount);
  setBetAmount(coinAmount * slot.lineSets[0]!.coinAmountMultiplier);
};

const checkBrokenGameFn = async () => {
  const userBonusData = await client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
  const userBonusesList = userBonusData.data.userBonuses;
  const isActiveUserBonus = Boolean(userBonusesList.length);

  if (isActiveUserBonus) {
    const activeBonus = userBonusesList[0] as UserBonus;
    setCurrentFreeSpinsTotalWin(0);
    if (userBonusesList.length > 1) {
      setAvailableBonuses(userBonusesList);
    } else {
      if (activeBonus!.bonus.type === 'SPECIAL_ROUND') {
        setCurrentBonusId(activeBonus!.id);
      } else {
        setCurrentBonus({
          ...activeBonus,
          gameMode: GameMode.FREE_SPINS,
          isActive: true,
          roundsLeft: activeBonus.rounds,
          currentMultiplier:
            activeBonus.data.dragonMultiplier + setUserLastBonusBet()?.data?.features?.gameRoundStore?.bonusMultiplier,
        });
      }
    }

    setCurrentBonus({
      ...setCurrentBonus(),
      coinAmount: activeBonus.coinAmount,
    });
    setBrokenGame(true);
  }
};

const LoadScreen: React.FC = () => {
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  const { data: slotLoadProgressInfo } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getSlotLoadProgressInfoGql);
  const { isSoundOn } = slotConfig!;
  const { progress } = slotLoadProgressInfo!;
  const [isShowContent, setShowContent] = useState(false);

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      setSlotConfig({
        ...setSlotConfig(),
        sessionId,
      });
      setIsAuthorized(!!slotLoadProgressInfo);
    },
  });

  useEffect(() => {
    const getUserBalance = getUserBalanceFn;
    const getPurchasableBonuses = getPurchasableBonusesFn;
    const getLastBet = getLastBetFn;
    const checkBrokenGame = checkBrokenGameFn;
    const getSlotData = getSlotDataFn;

    setShowContent(true);

    new Loader({ asynchronous: false })
      .stage(10, ELoaderStages.AUTH, async (stage) => {
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });
        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(30, ELoaderStages.BONUS_GAME, async (stage) => {
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets([...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES], process.env.PUBLIC_URL);
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(60, ELoaderStages.IMAGES, async (stage) => {
        setProgress({
          ...setProgress(),
          status: stage,
        });
        await wait(500);
      })
      .stage(80, ELoaderStages.AUDIO, async (_stage) => {
        AudioApi.initialize({
          audioSprite,
          audioVolume: audioSpriteVolume,
          restricted: setSkipIntroScreen(),
          isSoundEnabled: isSoundOn,
          onSuspended: setIsSuspended,
          audioBaseUrl: `${process.env.PUBLIC_URL}/sound`,
          environment: window.__ENV__?.ENV ?? Environments.DEVELOPMENT,
        }).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioApi.isRestricted && !(!AudioApi.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.GAME_READY, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
  }, []);

  if (!isShowContent) return null;

  return (
    <div className={styles['loadScreenWrapper']}>
      <div className={styles['logo']}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles['companyLogo']}
        />
      </div>
      <ProgressBar
        className={styles['progressBar']!}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
