import { TEXT_GRADIENT, TextStyle } from 'pixi.js';

export const multiplierTitleTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  dropShadowAngle: 1.2,
  // fill: ['#a6925c', '#c8b279', '#ab9363', '#a08b54'],
  // fillGradientStops: [0, 0.4, 0.48, 0.59, 1],
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontSize: 70,
  fontWeight: 'bolder',
  stroke: '#000000',
  strokeThickness: 6,
});

const winMessage = new TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 140,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 2,
  dropShadowColor: '#371a06',
  dropShadowDistance: 3,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientStops: [0.1, 0.3, 0.5, 0.7],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 150,
  stroke: '#110000',
  strokeThickness: 9,
  whiteSpace: 'normal',
  miterLimit: 1,
});

export const miniPayTableNumberStyle = {
  fontSize: 25,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xffffff',
};

export const miniPayTableMultipliersStyle = {
  fontSize: 35,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '0xf6e87e',
};

export const bottomContainerTextStyle = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#f6e87e',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
};

export const bigWinStyles = new TextStyle(winMessage);
export const megaWinStyles = new TextStyle(winMessage);
export const greatWinStyles = new TextStyle(winMessage);
export const epicWinStyles = new TextStyle(winMessage);
export const winValueStyles = new TextStyle(winCounterStyle);

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_FIT_TO_WIDTH = 160;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 2;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;

export const WinTextValueTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 2,
  dropShadowColor: '#371a06',
  dropShadowDistance: 3,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientStops: [0.1, 0.3, 0.5, 0.7],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 200,
  stroke: '#110000',
  strokeThickness: 9,
  whiteSpace: 'normal',
  miterLimit: 1,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };
