import _ from 'lodash';
import { Container, Graphics } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId, config } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsMiniPayTable } from '../../gql/cache';
import { is4SlotsReel } from '../../utils/helper';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import type { Combos, Icon } from '../d';
import type Slot from '../reels/slot';

import MiniPayTable from './miniPayTable';

class MiniPayTableContainer extends Container {
  private payTablePositions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18];

  private getSlotById: CallableFunction;

  private payTableContainers: Container[];

  private combosMap: Record<SlotId, Combos>;

  private icons: Icon[] = [];

  constructor(icons: Icon[], initialPaytableConfig: Icon[], getSlotByIdFn: CallableFunction) {
    super();
    this.icons = icons;
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.getSlotById = getSlotByIdFn;
    this.visible = setIsMiniPayTable();
    eventManager.addListener(EventTypes.DISABLE_PAYTABLE, () => (this.visible = false));
    eventManager.addListener(EventTypes.ENABLE_PAYTABLE, () => (this.visible = true));
    this.payTableContainers = [];

    this.combosMap = _.mapValues(
      MAPPED_SYMBOLS,
      (slug) => config.payTableData.find((d) => d.slug === slug.default)?.combos as Combos,
    );
    this.createContainers();
    this.updateMiniPaytable(initialPaytableConfig);
  }

  private createContainers() {
    this.payTablePositions.forEach((position) => {
      this.createPaytableContainer(Math.floor(position / REELS_AMOUNT), position % REELS_AMOUNT);
    });
  }

  private createPaytableContainer(row: number, col: number) {
    const index = row * REELS_AMOUNT + col;
    const slot = this.getSlotById(index) as Slot;
    const icon = this.icons.find((icon) => icon.id === slot.slotId)!;
    const container = new Container();
    const rect = this.getSlotShapeGraphics();

    container.width = SLOT_HEIGHT;
    container.height = SLOT_WIDTH;
    container.x = this.xPosition(row, col);
    container.y = this.yPosition(row, col);
    container.zIndex = 1;
    container.interactive = true;
    container.name = 'Paytable Container';

    rect.alpha = 0;
    container.addChild(rect);
    container.on('mousedown', () => {
      eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
    });
    container.on('touchstart', () => {
      eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
    });
    const payTable = new MiniPayTable(index, icon, this.combosMap[icon.id]);
    container.addChild(payTable);
    this.payTableContainers.push(container);
    this.addChild(container);
  }

  private getSlotShapeGraphics() {
    const rect = new Graphics();

    const radius = 130; // Radius of the hexagon
    const sideLength = radius * Math.sqrt(3); // Length of each side of the hexagon
    const centerX = 0;
    const centerY = 0;

    const hexagonHeight = (sideLength * Math.sqrt(3)) / 2;
    const hexagonWidth = sideLength;

    const startX = centerX - hexagonWidth / 2;
    const startY = centerY - hexagonHeight / 2;

    rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);

    rect.moveTo(startX, startY + hexagonHeight / 2);
    rect.lineTo(startX + hexagonWidth / 4, startY);
    rect.lineTo(startX + (3 * hexagonWidth) / 4, startY);
    rect.lineTo(startX + hexagonWidth, startY + hexagonHeight / 2);
    rect.lineTo(startX + (3 * hexagonWidth) / 4, startY + hexagonHeight);
    rect.lineTo(startX + hexagonWidth / 4, startY + hexagonHeight);
    rect.lineTo(startX, startY + hexagonHeight / 2);

    rect.endFill();

    return rect;
  }

  private xPosition(_row: number, col: number): number {
    return REEL_WIDTH + REEL_WIDTH * 0.75 * col;
  }

  private yPosition(row: number, _col: number): number {
    return SLOT_HEIGHT * row + (is4SlotsReel(_col) ? 0 : SLOT_HEIGHT / 2);
  }

  public updateMiniPaytable(spinResult: Icon[]): void {
    this.payTableContainers.forEach((container, index) => {
      const payTable = container!.children[1] as MiniPayTable;
      const mappedIndex = index < 15 ? index : (this.payTablePositions[index] as number);
      const iconId = spinResult[mappedIndex]?.id as SlotId;
      payTable.setPayTableData(iconId, this.combosMap[iconId as SlotId]);
    });

    this.visible = true;
  }
}

export default MiniPayTableContainer;
