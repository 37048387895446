import type { ITextStyle, Texture } from 'pixi.js';

import { SpriteButton, SpriteButtonProps, SpriteButtonState } from './SpriteButton';

export interface FeatureButtonProps extends SpriteButtonProps {
  [SpriteButtonState.ACTIVE]?: {
    texture: Texture;
    textStyle?: Partial<ITextStyle>;
    subTitleTextStyle?: Partial<ITextStyle>;
  };
  isActive?: boolean;
}
export class FeatureButton extends SpriteButton {
  private isActive = false;

  constructor(props: FeatureButtonProps) {
    super(props);
    this.isActive = !!props.isActive;
    this.state = this.isActive ? SpriteButtonState.ACTIVE : SpriteButtonState.DEFAULT;
    this.textures[SpriteButtonState.ACTIVE] = props[SpriteButtonState.ACTIVE]
      ? props[SpriteButtonState.ACTIVE]!.texture
      : props[SpriteButtonState.DEFAULT].texture;
    if (this.isActive) {
      this.changeState(SpriteButtonState.PRESSED);
    }
    if (this.textStyles) {
      this.textStyles[SpriteButtonState.ACTIVE] = props[SpriteButtonState.ACTIVE]?.textStyle as Partial<ITextStyle>;
      this.textField?.setStyle(
        this.isActive ? this.textStyles[SpriteButtonState.ACTIVE]! : this.textStyles[SpriteButtonState.DEFAULT]!,
      );
    }
    if (this.subTitleTextStyles) {
      this.subTitleTextStyles[SpriteButtonState.ACTIVE] = props[SpriteButtonState.ACTIVE]
        ?.subTitleTextStyle as Partial<ITextStyle>;
      this.subTitle?.setStyle(
        this.isActive
          ? this.subTitleTextStyles[SpriteButtonState.ACTIVE]!
          : this.subTitleTextStyles[SpriteButtonState.DEFAULT]!,
      );
    }
  }

  public turnOn(): void {
    this.isActive = true;
    this.changeState(SpriteButtonState.ACTIVE);
  }

  public turnOff(): void {
    this.isActive = false;
    if (this.state === SpriteButtonState.DISABLED) {
      return;
    }
    this.changeState(SpriteButtonState.DEFAULT);
  }

  public override enable(): void {
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
    }
  }

  protected override onMouseOverCallback(): void {
    if (!this.isActive) super.onMouseOverCallback();
  }

  protected override onClickCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.onClick) {
      this.onClick();
    }
  }

  protected override onMouseOutCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
    }
  }

  protected override onMouseDownCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    this.changeState(SpriteButtonState.PRESSED);
  }
}
