import { isMobile } from 'mobile-device-detect';
import { ITrackEntry, Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';

import type { FreeSpinsEndPopup } from './freeSpinsEndPopup';

class FreeSpinsEndPopupBackgroundAnimated extends ViewContainer {
  public spine: Spine;

  private freeSpinsEndPopup: FreeSpinsEndPopup;

  private showAnimationTrack!: ITrackEntry;

  private hideAnimationTrack!: ITrackEntry;

  constructor(popup: FreeSpinsEndPopup) {
    super();
    this.freeSpinsEndPopup = popup;
    this.spine = new Spine(Loader.shared.resources['bigWinMessages']!.spineData!);
    this.addChild(this.spine);
  }

  protected override resize(width: number, height: number): void {
    const isLandscape = width >= height;
    this.spine.y = isMobile && !isLandscape ? -50 : 0;
  }

  public show(): void {
    this.freeSpinsEndPopup.visible = true;
    this.showAnimationTrack = this.spine.state.setAnimation(0, 'you_won_mode_in', false);
    this.spine.state.addAnimation(0, 'you_won_mode_loop', true, 0);
  }

  public hide(): void {
    this.hideAnimationTrack = this.spine.state.setAnimation(0, 'you_won_mode_out', false);
    this.hideAnimationTrack.listener = {
      complete: (_entry: { trackIndex: number }) => {
        this.freeSpinsEndPopup.visible = false;
        eventManager.emit(EventTypes.END_FREE_SPINS);
      },
    };
  }
}

export default FreeSpinsEndPopupBackgroundAnimated;
