import * as PIXI from 'pixi.js';

import type { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

class Backdrop extends ViewContainer {
  private backdrop = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backdrop));

  constructor(openOn: EventTypes, closeOn: EventTypes) {
    super();

    this.addChild(this.backdrop);
    this.backdrop.visible = false;
    this.backdrop.alpha = 0;
    this.backdrop.interactive = true;
    this.backdrop.x = -this.backdrop.width / 2;
    this.backdrop.y = -this.backdrop.height / 2;
    this.scale.set(4, 4);

    this.trackVisibility(openOn, closeOn);
  }

  public trackVisibility(openOn: EventTypes, closeOn: EventTypes): void {
    eventManager.on(openOn, () => {
      this.backdrop.visible = true;

      const fadeInAnimation = new Tween({
        propertyBeginValue: 0,
        target: 0.75,
        object: this.backdrop,
        property: TweenProperties.ALPHA,
        duration: 300,
      });
      fadeInAnimation.start();
    });
    eventManager.on(closeOn, () => {
      const fadeOutAnimation = new Tween({
        propertyBeginValue: 0.75,
        target: 0,
        object: this.backdrop,
        property: TweenProperties.ALPHA,
        duration: 300,
      });

      fadeOutAnimation.addOnComplete(() => (this.backdrop.visible = false));
      fadeOutAnimation.start();
    });
  }
}
export default Backdrop;
