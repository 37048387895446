import { Group, Layer } from '@pixi/layers';
import { Spine } from 'pixi-spine';
import { Loader } from 'pixi.js';

// import AudioApi from '@phoenix7dev/audio-api';
import { MAPPED_SYMBOLS_WIN_ANIMATIONS, SlotId } from '../../../config';
import { EventTypes, GameMode, ISettledBet, Payline } from '../../../global.d';
import { setCurrentIsTurboSpin, setIsAutoSpins } from '../../../gql/cache';
import { Logic } from '../../../logic';
import { isSlotFrom4SlotsReel, nextTick } from '../../../utils';
import type Animation from '../../animations/animation';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import { REELS_AMOUNT, REEL_WIDTH, SLOT_HEIGHT, SLOT_SCALE, eventManager } from '../../config';
import type { Icon } from '../../d';
import { Dragon } from '../../dragon/dragon';

import type { WinSlotsContainer } from './winSlotsContainer';

export class BaseWinSlotsPresentation extends ViewContainer implements WinSlotsContainer {
  private allSlotsHighlight: AnimationChain | null = null;

  private animationsArr: Spine[] = [];

  private loopAnimation: Animation | null = null;

  public layer: Layer;

  public layersGroup: Group;

  public static the: BaseWinSlotsPresentation;

  private collectIsTriggered = false;

  constructor() {
    super();
    BaseWinSlotsPresentation.the = this;
    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = layer.name === 'moneySpine' ? 100 : 200;
    });
    this.layer = new Layer(this.layersGroup);
    this.addChild(this.layer);
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.showWin.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_ANIMATION, this.skipWinSlotsAnimation.bind(this));
  }

  private skipWinSlotsAnimation(): void {
    this.destroySpineWinAnimations();
    this.allSlotsHighlight?.skip();
    this.loopAnimation?.skip();
  }

  private destroySpineWinAnimations(): void {
    if (this.animationsArr.length) {
      this.animationsArr.forEach((spine) => {
        spine?.skeleton?.setToSetupPose();
        if (spine.state) {
          spine.state.tracks = [];
          nextTick(() => spine.destroy());
        }
      });
      this.animationsArr = [];
    }
  }

  public showWin(betResult: ISettledBet, paylinesData: Payline[]): void {
    this.animationsArr = [];
    const isFreeSpins = Logic.the.controller.gameMode === GameMode.FREE_SPINS;
    const paylines = paylinesData;
    const { spinResult } = betResult.bet.result;
    const currentSpinResult = [...spinResult];
    const paylinesPositions = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        paylinesPositions.add(position);
      });
    });
    const slots = Array.from(paylinesPositions).sort((a, b) => a - b);

    this.allSlotsHighlight = this.highlightSlots(slots, spinResult);
    const delayToStartNextAnimation = Tween.createDelayAnimation(setCurrentIsTurboSpin() ? 250 : 500);
    delayToStartNextAnimation.addOnComplete(() => {
      this.destroySpineWinAnimations();
      // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.WIN_LINE_ANIMATION_END);
      if (!isFreeSpins && !setIsAutoSpins()) this.loopAnimation?.start();
      if (isFreeSpins || setIsAutoSpins()) eventManager.emit(EventTypes.SHOW_TINT, false);
    });
    this.allSlotsHighlight.appendAnimation(delayToStartNextAnimation);
    this.allSlotsHighlight.addOnStart(() => {
      if (paylines.some((payline) => payline.lineId !== null)) {
        eventManager.emit(EventTypes.SHOW_TINT, true);
        // eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines); // @ No win lines on this game
      }
    });
    this.allSlotsHighlight.addOnSkip(() => {
      if (this.collectIsTriggered) this.collectIsTriggered = false;
      eventManager.emit(EventTypes.SHOW_TINT, false);
      // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.WIN_LINE_ANIMATION_END);
    });
    this.loopAnimation = this.createWinLineAnimation(currentSpinResult, paylines, true);

    this.loopAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      if (this.collectIsTriggered) this.collectIsTriggered = false;
    });

    this.allSlotsHighlight.start();
  }

  public createSlotSpineAnimation(id: number, srcName: string, animationName: string): Animation {
    const dummy = Tween.createDelayAnimation(1333);

    dummy.addOnStart(() => {
      const animation = new Spine(Loader.shared.resources[srcName as string]!.spineData!);
      const x = REEL_WIDTH + REEL_WIDTH * 0.75 * (id % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + (isSlotFrom4SlotsReel(id) ? 0 : SLOT_HEIGHT / 2);
      animation.position.set(x, y);
      animation.scale.set(SLOT_SCALE);

      this.addChild(animation);

      animation.state.setAnimation(0, animationName, false);
      this.animationsArr.push(animation);
    });

    return dummy;
  }

  public triggerWildAnimationOnDragonAttack(positions: number[]) {
    positions.forEach((position) => {
      this.createSlotSpineAnimation(position, 'vfx_wild_fs', `vfx_wild_${Dragon.the.dragonType}`).start();
    });
  }

  public createWinLineAnimation(spinResult: Icon[], paylines: Payline[], isLoop: boolean): Animation {
    const isTurboSpin = setCurrentIsTurboSpin();
    const chain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const animationGroup = new AnimationGroup();
      payline.winPositions.forEach((slotId) => {
        const symbolId = spinResult[slotId as number]!.id;
        animationGroup.addAnimation(
          this.createSlotSpineAnimation(
            slotId,
            MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].src!,
            MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].animation!,
          ),
        );
      });
      animationGroup.addOnStart(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, false);
        if (payline.lineId !== null) {
          // eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        }
      });
      animationGroup.addOnComplete(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, true);
      });
      animationGroup.addOnSkip(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, true);
      });
      const delayToStartNextAnimation = Tween.createDelayAnimation(isTurboSpin ? 250 : 500);
      delayToStartNextAnimation.addOnComplete(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        this.destroySpineWinAnimations();
      });
      delayToStartNextAnimation.addOnSkip(() => {
        // eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        this.destroySpineWinAnimations();
      });
      chain.appendAnimation(animationGroup);
      chain.appendAnimation(delayToStartNextAnimation);
    });
    return chain;
  }

  public highlightSlots(slots: number[], spinResult: Icon[]): AnimationChain {
    const chain = new AnimationChain();
    const animationGroup = new AnimationGroup({});
    slots.forEach((slotId, _index) => {
      const symbolId = spinResult[slotId as number]!.id;
      animationGroup.addAnimation(
        this.createSlotSpineAnimation(
          slotId,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].src!,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].animation!,
        ),
      );
    });
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], false);
    });
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
    });
    animationGroup.addOnSkip(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
    });
    chain.appendAnimation(animationGroup);
    return chain;
  }

  public clearSpines() {
    if (this.children.length > 1) {
      this.children.forEach((child) => {
        if (child instanceof Spine) {
          child?.skeleton?.setToSetupPose();
          if (child.state) {
            child.state.tracks = [];
            nextTick(() => child.destroy());
          }
        }
      });

      // Will remove all except layer
      this.removeChildren(1);
    }
  }
}
