import * as PIXI from 'pixi.js';

import SpineAnimation from '../animations/spine';

class Payline extends PIXI.Container {
  private id: number;

  private spine: SpineAnimation | undefined;

  constructor(id: number) {
    super();
    this.id = id;

    this.spine = new SpineAnimation({}, PIXI.Loader.shared.resources!['paylines']!.spineData!);

    this.spine.addOnStart((): void => {
      this.spine!.setAnimation(`line_position_${this.id + 1}`, true);
      const spineData = this.spine!.getSpine();
      this.addChild(spineData);
    });
    this.visible = false;
  }

  public show(visibility: boolean): void {
    this.spine!.start();
    this.visible = visibility;
  }
}

export default Payline;
