import type React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';

const AutoPlay: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['autoplay']}>
      <h1 className={styles['title']}>{t('infoAutoPlayTitle')}</h1>
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay1'),
        }}
      />
      <br />
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay1_1'),
        }}
      />
      <br />
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay2'),
        }}
      />
      <br />
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay3'),
        }}
      />
      <br />
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay4'),
        }}
      />
      <br />
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay5'),
        }}
      />
      <br />
      <div
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('infoAutoPlay6'),
        }}
      />
    </div>
  );
};

export default AutoPlay;
