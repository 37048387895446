import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Toast } from '@phoenix7dev/shared-components';

import { EventTypes } from '../../global.d';
import { setIsProceedToGame, setIsShowSoundToast, setIsSoundLoading, setIsSoundOn } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { slotConfigGql } from '../../gql/query';
import { Logic } from '../../logic';
import { eventManager } from '../../slotMachine/config';
import { handleChangeRestriction } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import Clock from '../Clock';
import EventListener from '../EventListener';
import Menu from '../MenuButton';
import Spin from '../SpinButton';
import TitleName from '../TitleName';

import styles from './gameScreen.module.scss';

(window as unknown as { PIXI: unknown }).PIXI = PIXI;

const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data: slotConfig } = useQuery<IConfig>(slotConfigGql);
  const { isSoundOn, isShowSuspendedToast } = slotConfig!;
  const isSoundLoading = useReactiveVar(setIsSoundLoading);
  const isProceedToGame = useReactiveVar(setIsProceedToGame);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    pixiContainerRef.current?.appendChild(Logic.the.application.view);
    Logic.the.init();
  }, []);

  return (
    <>
      <div className={styles['canvas']} ref={pixiContainerRef} />
      {isProceedToGame && (
        <>
          <EventListener />
          <Clock />
          <TitleName />
          <AutoPlaySettingsMenu />
          <Menu />
          <BetSettingsMenu />
          <Spin />
          {AudioApi.isRestricted &&
            !AudioApi.restrictionChangedOnIntroScreen &&
            !AudioApi.hideRestrictionModal &&
            !isShowSuspendedToast &&
            setIsSoundOn() && (
              <Toast
                title={t('soundWarningTitle')!}
                text={t('soundWarningText')!}
                btnText={t('soundWarningBtn')!}
                isLoading={isSoundLoading}
                handleClick={() => {
                  if (!AudioApi.isInitialized) {
                    eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
                  }
                  AudioApi.changeRestriction(
                    false,
                    [],
                    () => setIsSoundLoading(true),
                    () => {
                      setIsShowSoundToast(false);
                      handleChangeRestriction(Logic.the.controller.gameMode);
                    },
                  );
                }}
              />
            )}
          {isShowSuspendedToast && setIsSoundOn() && (
            <Toast
              title={t('suspendedWarningTitle')!}
              text={t('suspendedWarningText')!}
              btnText={t('suspendedWarningBtn')!}
              handleClick={() => {
                handleChangeRestriction(Logic.the.controller.gameMode);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default GameScreen;
