import i18n from 'i18next';
import { Container, Text } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes, FeatureState } from '../../../global.d';
import { setIsBuyFeatureFailed } from '../../../gql/cache';
import { Logic } from '../../../logic';
import { States } from '../../../logic/config';
import { BaseController } from '../../../logic/controllers/BaseController';
import { getBonusIdByFeature } from '../../../utils';
import { TextField } from '../../components/TextField';
import { eventManager } from '../../config';
import { ModalService } from '../../popups/ModalService';

import { BuyFeaturePopup } from './buyFeaturePopup';
import { buyFeatureWhiteText, buyFeatureYellowText } from './buyFeaturePopup.model';
import { BuyFeatureWrapper } from './buyFeatureWrapper';

export class BuyFeatureConfirmPopup extends Container {
  public override name = 'BuyFeatureConfirmPopup';
  private wrapper = new BuyFeatureWrapper(
    () => this.startBuyFeature(),
    () => this.backToBuyFeature(),
  );
  constructor(private totalCost: number) {
    super();
    this.addChild(this.wrapper);
    this.wrapper.add(this.getConfirmTitle(), this.getConfirmSubTitle());
    this.wrapper.changeTotalCost(totalCost);
    eventManager.once(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.backToBuyFeature(true);
    });
  }

  private backToBuyFeature(skipBuyFeature?: boolean): void {
    if (skipBuyFeature) {
      setIsBuyFeatureFailed(true);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      Logic.the.changeState(States.IDLE);
      Logic.the.controller = BaseController.the;
      Logic.the.controller.enterController(null);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      eventManager.emit(EventTypes.CLOSE_POPUP);
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      ModalService.the.hide();
      ModalService.the.open(new BuyFeaturePopup());
    }
  }

  private startBuyFeature(): void {
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(FeatureState.FREE_SPINS));

    AudioApi.play({ type: ISongs.BuyButton });
    ModalService.the.hide();
  }

  private getConfirmTitle(): Text {
    const textField = new TextField(i18n.t<string>('buyFeatureConfirmTitle'), 360, 52, buyFeatureWhiteText);
    textField.text.y = 228;

    return textField.getText();
  }

  private getConfirmSubTitle(): Text {
    const textField = new TextField(i18n.t<string>('buyFeatureConfirmSubTitle'), 207, 64, buyFeatureYellowText);
    textField.text.y = 149;

    return textField.getText();
  }
}
