import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [20, 50, 75, 100],
    minBet: 20,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.wl,
      title: 'infoPayTable_wL',
      description: 'infoPayTable_wL_descr',
      combos: [],
    },
    {
      slug: ResourceTypes.wlA,
      title: 'infoPayTable_wL_A',
      description: 'infoPayTable_wL_A_descr',
      combos: [],
    },
    {
      title: 'infoPayTable_wL_B',
      description: 'infoPayTable_wL_B_descr',
      slug: ResourceTypes.wlB,
      combos: [],
    },
    {
      slug: ResourceTypes.wlC,
      title: 'infoPayTable_wL_C',
      description: 'infoPayTable_wL_C_descr',
      combos: [],
    },
    {
      slug: ResourceTypes.sc,
      title: 'infoPayTable_sC',
      combos: [{ pattern: 'x3', multiplier: 40 }],
    },
    {
      slug: ResourceTypes.a,
      combos: [
        { pattern: 'x5', multiplier: 800 },
        { pattern: 'x4', multiplier: 160 },
        { pattern: 'x3', multiplier: 80 },
      ],
    },
    {
      slug: ResourceTypes.b,
      combos: [
        { pattern: 'x5', multiplier: 600 },
        { pattern: 'x4', multiplier: 120 },
        { pattern: 'x3', multiplier: 60 },
      ],
    },
    {
      slug: ResourceTypes.c,
      combos: [
        { pattern: 'x5', multiplier: 400 },
        { pattern: 'x4', multiplier: 80 },
        { pattern: 'x3', multiplier: 40 },
      ],
    },
    {
      slug: ResourceTypes.d,
      combos: [
        { pattern: 'x5', multiplier: 200 },
        { pattern: 'x4', multiplier: 40 },
        { pattern: 'x3', multiplier: 20 },
      ],
    },
    {
      slug: ResourceTypes.e,
      combos: [
        { pattern: 'x5', multiplier: 80 },
        { pattern: 'x4', multiplier: 16 },
        { pattern: 'x3', multiplier: 8 },
      ],
    },
    {
      slug: ResourceTypes.f,
      combos: [
        { pattern: 'x5', multiplier: 60 },
        { pattern: 'x4', multiplier: 12 },
        { pattern: 'x3', multiplier: 6 },
      ],
    },
    {
      slug: ResourceTypes.g,
      combos: [
        { pattern: 'x5', multiplier: 40 },
        { pattern: 'x4', multiplier: 8 },
        { pattern: 'x3', multiplier: 4 },
      ],
    },
    {
      slug: ResourceTypes.h,
      combos: [
        { pattern: 'x5', multiplier: 20 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
    {
      slug: ResourceTypes.i,
      title: 'infoPayTable_i',
      description: 'infoPayTable_i_descr',
      combos: [],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  SC = 'SC1',
  WL = 'WL',
  WL_A = 'WL1',
  WL_B = 'WL2',
  WL_C = 'WL3',
  I = 'I',
}
