import i18n from 'i18next';
import _ from 'lodash';
import MultiStyleText from 'pixi-multistyle-text';
import { Container, Graphics, Sprite, Texture } from 'pixi.js';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setCoinAmount, setCurrency, setIsMiniPayTable } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import type { TextField } from '../components/TextField';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  REELS_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  eventManager,
  miniPayTableMultipliersStyle,
} from '../config';
import type { Combos, Icon, IconCombo } from '../d';

// Here we will have other special symbols (Scatter, Wild)
type SpecialSymbolType = 'WL';

class MiniPayTable extends Container {
  private id: number;

  private isLast: boolean;

  private currency = '';

  private betAmount!: number;

  public iconId: SlotId;

  public combos: Combos;

  public rect!: Graphics;

  public multipliers!: MultiStyleText;

  public symbol!: Sprite;

  public specialSymbolDescription!: TextField;

  // We will fill here title and description per special symbol
  public specialSymbolDetails: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [key in SpecialSymbolType]: string;
  } = {
    WL: 'infoPayTableWildText',
  };

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.isLast = id % REELS_AMOUNT === REELS_AMOUNT - 1;
    // TODO: re-adjustment
    this.x = this.isLast ? -1.5 * SLOT_WIDTH : -SLOT_WIDTH / 2;
    this.y = -SLOT_HEIGHT / 2;

    this.iconId = icon.id;
    this.width = SLOT_WIDTH * 2;
    this.height = SLOT_HEIGHT;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => {
      this.showPayTable(i);
    });
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, () => (this.visible = false));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventManager.addListener(EventTypes.UPDATE_BET, (betAmount: number) => this.handleChangeBetAmount(betAmount));
    // rect
    this.initRect();
    this.addChild(this.rect);
    // symbol
    this.initSymbol();
    this.addChild(this.symbol);
    // multipliers
    this.initMultipliers();
    this.addChild(this.multipliers);

    this.currency = setCurrency();
    this.setPayTableData(icon.id, combos);
  }

  initRect(): void {
    this.rect = new Graphics();
    this.rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
    this.rect.alpha = 0.75;
    this.rect.drawRoundedRect(
      this.isLast ? -5 : SLOT_WIDTH / 2,
      (SLOT_HEIGHT - PAY_TABLE_HEIGHT) / 2,
      PAY_TABLE_WIDTH,
      PAY_TABLE_HEIGHT,
      15,
    );
    this.rect.zIndex = 2;
  }

  initSymbol(): void {
    this.symbol = new Sprite();
    this.symbol.x = this.isLast ? SLOT_WIDTH : 0;
    this.symbol.anchor.set(0.5, 0.5);
    this.symbol.width = SLOT_WIDTH;
    this.symbol.height = SLOT_HEIGHT;
    this.symbol.zIndex = 3;
  }

  initMultipliers(): void {
    this.multipliers = new MultiStyleText('x5: 1488', {
      default: {
        ...miniPayTableMultipliersStyle,
        align: 'left',
      },
      span: {
        fill: 0xffffff,
      },
      p: {
        fontSize: 30,
        fontFamily: 'NotoSans-SemiCondensedBold',
        fill: 0xffffff,
        wordWrap: true,
        wordWrapWidth: SLOT_WIDTH,
      },
    });

    this.multipliers.x = this.isLast ? 20 : SLOT_WIDTH;
    this.multipliers.zIndex = 3;
    this.multipliers.anchor.set(0, 0.5);
  }

  setPayTableData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;
    this.symbol.texture = Texture.from(MAPPED_SYMBOLS[iconId as SlotId].default);
    this.symbol.width = SLOT_WIDTH * SLOT_SCALE;
    this.symbol.height = SLOT_HEIGHT * SLOT_SCALE;
    this.symbol.position.set(this.isLast ? SLOT_WIDTH + SLOT_WIDTH / 2 : SLOT_WIDTH / 2, SLOT_HEIGHT / 2);
    this.multipliers.text = this.getCombos();
    this.multipliers.y = SLOT_HEIGHT / 2;

    if (this.getSpecialSymbolInfo()) {
      this.multipliers.x = this.isLast ? 120 : SLOT_WIDTH * 1.4;
      this.multipliers.anchor.set(0.5);
      this.multipliers.style = {
        align: 'center',
        fontSize: 30,
        fill: 0xffffff,
        wordWrap: true,
        wordWrapWidth: SLOT_WIDTH,
      };

      if (this.multipliers.width > SLOT_WIDTH - 50) {
        this.multipliers.scale.set((SLOT_WIDTH - 50) / this.multipliers.width);
      }
    } else {
      this.multipliers.x = this.isLast ? 20 : SLOT_WIDTH;
      this.multipliers.anchor.set(0, 0.5);
      this.multipliers.style = {
        align: 'left',
        wordWrap: false,
      };
      this.multipliers.scale.set(1);
    }
  }

  private calcMultiplier(multiplier: number): number {
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  private getCombos(): string {
    const specialSymbolText = this.getSpecialSymbolInfo();
    return !specialSymbolText
      ? (
          this.combos?.reduce(
            (acc: string, curr: IconCombo) =>
              `${acc} <span>${curr.pattern}:</span> ${formatNumber({
                currency: this.currency,
                value: this.calcMultiplier(curr.multiplier),
                showCurrency: showCurrency(this.currency),
              })}\n`,
            '',
          ) || ''
        ).trimEnd()
      : `<p>${i18n.t(specialSymbolText)}</p>`;
  }

  public showPayTable(uniqueId: number): void | undefined {
    const noPaytableSymbols = [SlotId.WL, SlotId.WL_A, SlotId.WL_B, SlotId.WL_C, SlotId.I];
    if (!setIsMiniPayTable() || noPaytableSymbols.includes(this.iconId)) return;

    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }
    this.correctSize();
    this.visible = !this.visible;
  }

  private handleChangeBetAmount(_betAmount: number): void {
    this.multipliers.text = this.getCombos();
    this.correctSize();
  }
  private correctSize(): void {
    this.multipliers.scale.set(1);
    const scale = this.multipliers.width > SLOT_WIDTH - 50 ? (SLOT_WIDTH - 50) / this.multipliers.width : 1;
    this.multipliers.scale.set(scale);
  }

  private getSpecialSymbolInfo(): string {
    const specialSymbolDetails = this.specialSymbolDetails[this.iconId as SpecialSymbolType];
    return specialSymbolDetails || '';
  }
}

export default MiniPayTable;
