/* eslint-disable security/detect-object-injection */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Group, Layer } from '@pixi/layers';
import _ from 'lodash';
import { Container, Graphics } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../../config';
import { EventTypes, GameMode, ISettledBet } from '../../../global.d';
import {
  setBetAmount,
  setBetResult,
  setCurrentIsTurboSpin,
  setCurrentReelSetId,
  setIsAutoSpins,
  setIsPhoenixAnticipationInProgress,
  setLastSpinData,
  setSlotConfig,
} from '../../../gql/cache';
import { Logic } from '../../../logic';
import { States } from '../../../logic/config';
import { getBetResult, getSlotOrderBySlotId, is4SlotsReel, normalizeCoins } from '../../../utils';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import type { BaseAnimation } from '../../animations/reel/baseAnimation';
import type { ReelAnimation } from '../../animations/reel/reelAnimation';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import {
  ANTICIPATION_DURATION,
  ANTICIPATION_DURATION_TURBOSPIN,
  ANTICIPATION_ENABLE,
  ANTICIPATION_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  BASE_REEL_ENDING_FORMULA,
  REELS_AMOUNT,
  ReelState,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../../config';
import type { Icon } from '../../d';
import { Dragon } from '../../dragon/dragon';
import { BonusDragonMovements, BonusDragons } from '../../dragon/dragon.model';
import { BaseWinSlotsPresentation } from '../../winAnimations/winSlotsPresentation/baseWinSlotsPresentation';
import { BaseReel } from '../reel/baseReel';
import type { Reel } from '../reel/reel';
import type Slot from '../slot';

import type { ReelsContainer } from './reelsContainer';

class BaseReelsContainer extends ViewContainer implements ReelsContainer {
  public reels: (Reel & ViewContainer)[] = [];

  public landedReels: number[] = [];

  public isSoundPlayed = false;

  public isForceStopped = false;

  public layer: Layer;

  public slotGroup: Group;

  private wildPositions: number[] = [];

  private activeTimeoutId: unknown;

  private onDragonAttack = () => {
    this.playDragonAttackSlotsAnimations(this.wildPositions);
  };

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.name = BaseReelsContainer.name;
    this.slotGroup = new Group(1, (slot) => {
      slot.zOrder = getSlotOrderBySlotId((slot as Slot).slotId);
    });
    this.layer = new Layer(this.slotGroup);
    this.initReels(reels, startPosition, this.slotGroup);
    Logic.the.currentSpinResult = this.getCurrentSpinResult();
    // this.addChild(this.layer);

    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.setSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.REMOVE_BAGS_MULTIPLIER, this.removeBagsMultiplier.bind(this));

    eventManager.addListener(EventTypes.CHANGE_REELS_DATA, this.changeReelsData.bind(this));

    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
    eventManager.addListener(EventTypes.REEL_LANDED, this.checkLandedReels.bind(this));
    eventManager.addListener(EventTypes.SET_COLLECT_VALUE, (collectValue: number) => {
      const collectSlot = this.reels[4]?.slots.filter((slot) => slot.slotId === SlotId.C);
      if (collectSlot?.length) {
        if (collectValue) {
          collectSlot[0]!.setCollectMultiplier(collectValue);
        } else {
          collectSlot[0]!.resetCollectMultiplier();
        }
      }
    });
    eventManager.addListener(EventTypes.DRAGON_ATTACK, this.onDragonAttack.bind(this));

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.landedReels = [];
      this.isForceStopped = false;
      this.getSpinAnimation().start();
    });
    this.sortableChildren = true;
  }

  private removeBagsMultiplier(positions: number[]): void {
    positions.forEach((position) => {
      const x = position % REELS_AMOUNT;
      const y = Math.floor(position / REELS_AMOUNT);
      const result = getBetResult(setBetResult());
      const p =
        (this.reels[x as number]!.slots.length + result.bet.result.reelPositions[x as number]! + y - 1) %
        this.reels[x as number]!.slots.length;
      this.reels[x as number]!.slots[p as number]!.removeMoneyMultiplier();
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.BASE_GAME && setLastSpinData().layout.length) {
      this.changeReelsData(setLastSpinData());
    }
  }

  public checkLandedReels(id: number): void {
    this.landedReels.push(id);

    if (this.landedReels.length === REELS_AMOUNT) {
      this.landedReels = [];

      eventManager.emit(EventTypes.REELS_STOPPED);

      const result = getBetResult(setBetResult());
      const isFeatureWin = result.bet.data.bonuses.length > 0;

      if (isFeatureWin) {
        AudioApi.play({
          type: ISongs.SFX_WIN_FeatureTrigger,
        });
      }
    }
  }

  private throwTimeoutError(): void {
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private removeErrorHandler(): void {
    this.reels[REELS_AMOUNT - 1]!.animation?.getWaiting().removeOnComplete(this.throwTimeoutError);
  }

  public getCurrentSpinResult(): Icon[] {
    const spinResult: Icon[] = [];
    //@WARNING: SLOTS_PER_REEL_AMOUNT + 1
    for (let j = 0; j < SLOTS_PER_REEL_AMOUNT + 1; j++) {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        spinResult.push(
          setSlotConfig().icons.find((icon) => {
            const reel = this.reels[i as number] as BaseReel;
            return (
              icon.id ===
              reel.slots.find((slot) => slot.id === (reel.stopPosition + j + reel.size - 1) % reel.size)!.slotId
            );
          })!,
        );
      }
    }

    return spinResult;
  }

  private checkPhoenixAnticipation(): boolean {
    const placeBet = setBetResult() as ISettledBet;
    const showPhoenix = this.shouldDisplayPhoenix();
    const betAmount = normalizeCoins(setBetAmount());
    const normalizedWinAmount = normalizeCoins(placeBet.bet.result.winCoinAmount);
    const betWinRatio = normalizedWinAmount / betAmount;
    const winRatioToShowPhoenix = 200;

    if (betWinRatio >= winRatioToShowPhoenix && showPhoenix) {
      return true;
    }
    return false;
  }

  private shouldDisplayPhoenix(): boolean {
    const num = Math.random();
    const chanceRatio = 0.5;

    if (num < chanceRatio) {
      return false;
    } else {
      return true;
    }
  }

  private handleReelsStop() {
    const shouldShowPhoenix = this.checkPhoenixAnticipation();

    if (shouldShowPhoenix) {
      if (!Logic.the.isStoppedBeforeResult) {
        setIsPhoenixAnticipationInProgress(true);
        eventManager.emit(EventTypes.PHOENIX_START);

        setTimeout(() => {
          setIsPhoenixAnticipationInProgress(false);
          this.removeErrorHandler();
          this.setupReelsTarget(4000);
        }, 4000);
      } else {
        AudioApi.play({ type: ISongs.SFX_phoenix });

        this.removeErrorHandler();
        this.setupReelsTarget(0);
      }
    } else {
      this.removeErrorHandler();
      this.setupReelsTarget(0);
    }
  }

  public getSpinAnimation(): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reels[i as number];
      const spinAnimation: ReelAnimation = reel!.createSpinAnimation();
      if (i === REELS_AMOUNT - 1) {
        spinAnimation.getWaiting().addOnChange(() => {
          if (setBetResult() && !Logic.the.isReadyForStop) {
            Logic.the.isReadyForStop = true;
            this.handleReelsStop();
          }
        });
        spinAnimation.getWaiting().addOnComplete(this.throwTimeoutError);
      }
      this.reels[i as number]!.isPlaySoundOnStop = true;
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  public rollbackReels(): void {
    if (Logic.the.state.name === States.IDLE) return;
    if (Logic.the.controller.gameMode === GameMode.BUY_FEATURE) {
      eventManager.emit(EventTypes.FORCE_CLOSE_BUYFEATURE);
    } else {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        this.reels[i as number]!.animation?.getDisappearing().end();
        this.reels[i as number]!.animation?.getWaiting().end();
        this.reels[i as number]!.stopPosition = setLastSpinData()!.reelPositions[i as number] as number;
        this.reels[i as number]!.slots.forEach((slot, _id) => {
          slot.y = this.reels[i as number]!.getSlotY(slot);
          slot.toggleBlur(false);
        });
      }
      if (setIsAutoSpins()) setIsAutoSpins(false);
      Logic.the.changeState(States.IDLE);
    }
  }

  public changeReelsData(input: { reelPositions: number[]; layout: SlotId[][] }): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i as number]!.changeReelData(
        input.layout[i as number]!,
        this.slotGroup,
        input.reelPositions[i as number]!,
      );
    }
  }

  public initReels(reels: SlotId[][], startPosition: number[], slotGroup: Group): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reelMaskContainer = new Container();
      const mask = this.getMaskForReel(i);

      reelMaskContainer.name = 'ReelMaskContainer';

      const position = startPosition ? startPosition[i as number] : 0;
      const reel = new BaseReel(i, reels[i as number]!, position!, slotGroup);
      this.reels[i as number] = reel;

      reelMaskContainer.addChild(reel);
      reelMaskContainer.addChild(mask);
      reelMaskContainer.mask = mask;

      this.addChild(reelMaskContainer);
    }
  }

  private getMaskForReel(reelId: number) {
    const mask = new Graphics().beginFill(0xffffff);
    const columnX = SLOT_WIDTH + (SLOT_WIDTH - SLOT_WIDTH / 4) * reelId; // X coordinate of the column
    const columnY = SLOT_HEIGHT / 2 - (is4SlotsReel(reelId) ? SLOT_HEIGHT / 2 : 0);
    const spacingY = SLOT_HEIGHT; // Spacing between hexagons in the column
    const radius = 130; // Radius of the hexagon
    const sideLength = radius * Math.sqrt(3); // Length of each side of the hexagon
    const slotsCountInReel = is4SlotsReel(reelId) ? 4 : 3;

    for (let i = 0; i < slotsCountInReel; i++) {
      const centerX = columnX;
      const centerY = columnY + i * spacingY;

      const hexagonHeight = (sideLength * Math.sqrt(3)) / 2;
      const hexagonWidth = sideLength;

      const startX = centerX - hexagonWidth / 2;
      const startY = centerY - hexagonHeight / 2;

      mask.moveTo(startX, startY + hexagonHeight / 2);
      mask.lineTo(startX + hexagonWidth / 4, startY);
      mask.lineTo(startX + (3 * hexagonWidth) / 4, startY);
      mask.lineTo(startX + hexagonWidth, startY + hexagonHeight / 2);
      mask.lineTo(startX + (3 * hexagonWidth) / 4, startY + hexagonHeight);
      mask.lineTo(startX + hexagonWidth / 4, startY + hexagonHeight);
      mask.lineTo(startX, startY + hexagonHeight / 2);
    }

    mask.endFill();

    return mask;
  }

  public forceStopReels(): void {
    if (setIsPhoenixAnticipationInProgress()) return;

    this.isForceStopped = true;

    if (Logic.the.controller.gameMode === GameMode.FREE_SPINS) {
      Dragon.the.setAnimation(BonusDragonMovements.idle);
      clearTimeout(this.activeTimeoutId as number);
    }

    for (let i = 0; i < REELS_AMOUNT; i++) {
      const animation = this.reels[i as number]!.animation! as BaseAnimation;
      animation.getDisappearing().duration = 0;
      animation.getWaiting().duration = 0;
      animation.getAppearing().duration = 0;
    }
  }

  private getAnticipationReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT[i as number] as number;
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        if (spinResult[j as number]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT]!.id === symbolId) currentCount += 1;
        if (spinResult[j + REELS_AMOUNT * 2]!.id === symbolId) currentCount += 1;

        if (currentCount >= count) {
          minReelId = Math.min(minReelId, symbolId === SlotId.SC ? j + 2 : j + 1);
        }
      }
    });

    return minReelId;
  }

  public setupReelsTarget(waitingRollingDelay = 0): void {
    const isStopped = Logic.the.isStoppedBeforeResult;
    const isTurboSpin = setCurrentIsTurboSpin() && Logic.the.controller.gameMode === GameMode.BASE_GAME;
    const isFreeSpins = Logic.the.controller.gameMode === GameMode.FREE_SPINS;
    const result = getBetResult(setBetResult());
    const { spinResult } = result.bet.result;
    const anticipationReelId = this.getAnticipationReelId(result.bet.result.spinResult);
    const speed = isTurboSpin ? 40 : 25;
    const { reelPositions } = result.bet.result;
    this.isSoundPlayed = false;
    for (let j = 0; j < REELS_AMOUNT; j++) {
      let dragonAttackEndAfter = 0;
      const waitingDuration = (isTurboSpin ? 500 + j * 100 : 1250 + j * 300) + waitingRollingDelay;
      const reel = this.reels[j as number] as BaseReel;
      if (setCurrentReelSetId() !== result.bet.reelSet.id) {
        reel.changeData(result.bet.reelSet.layout[j as number]!, this.slotGroup);
      }
      const waitingAnimation = reel.animation!.getWaiting() as Tween;
      const diff = (speed * waitingDuration) / 1000 - SLOTS_PER_REEL_AMOUNT;
      const beginValue = reelPositions[j as number]! + diff;
      const appearingBegin = beginValue - diff;

      const appearingAnimation = new Tween({
        object: this.reels[j as number]!,
        target: reelPositions[j as number]!,
        propertyBeginValue: appearingBegin + 15,
        property: TweenProperties.STOP_POSITION,
        easing: BASE_REEL_ENDING_FORMULA,
        duration: isTurboSpin ? ANTICIPATION_DURATION_TURBOSPIN : ANTICIPATION_DURATION,
      });

      if (j >= anticipationReelId && !isStopped && !isFreeSpins) {
        waitingAnimation.propertyBeginValue = beginValue + speed * (j - anticipationReelId + 1);
        waitingAnimation.target = reelPositions[j as number]! + SLOTS_PER_REEL_AMOUNT;
        waitingAnimation.duration = waitingDuration * (j - anticipationReelId + 1);
        appearingAnimation.propertyBeginValue = appearingBegin + 15;
        appearingAnimation.duration = isTurboSpin ? ANTICIPATION_DURATION_TURBOSPIN : ANTICIPATION_DURATION;
        appearingAnimation.addOnStart(() => {
          const delay = this.isForceStopped
            ? 0
            : isTurboSpin
            ? ANTICIPATION_DURATION_TURBOSPIN
            : ANTICIPATION_DURATION - 800;
          const delayBeforeRunLanding = setTimeout(() => {
            // this.handleLandingReel(j);
            clearTimeout(delayBeforeRunLanding);
          }, delay);

          eventManager.emit(EventTypes.SET_COLLECT_VALUE, 0);
          this.reels[j as number]!.changeState(ReelState.APPEARING);
          eventManager.emit(EventTypes.ANTICIPATION_STARTS, j);
        });
        appearingAnimation.addOnChange(() => {
          this.reels[j as number]!.slots.forEach((slot, _i) => {
            slot.y = this.reels[j as number]!.getSlotY(slot);
          });
        });
        appearingAnimation.addOnComplete(() => {
          this.reels[j as number]!.changeState(ReelState.IDLE);
          eventManager.emit(EventTypes.REEL_LANDED, j);
        });
      } else {
        dragonAttackEndAfter = 0;
        waitingAnimation.propertyBeginValue = beginValue;
        waitingAnimation.target = reelPositions[j as number]! + SLOTS_PER_REEL_AMOUNT;
        waitingAnimation.duration = isStopped ? 0 : waitingDuration;
        if (waitingAnimation.ended) {
          waitingAnimation.duration = 1;
          waitingAnimation.target = 0;
          waitingAnimation.propertyBeginValue = 0;
          waitingAnimation.start();
        }
        appearingAnimation.propertyBeginValue = appearingBegin + SLOTS_PER_REEL_AMOUNT;
        appearingAnimation.duration = isStopped ? 0 : 250;

        if (isFreeSpins && !isStopped) {
          if (j === 0 && this.isWildsOnDifferentReels(spinResult)) {
            const wildPositions: number[] = [];

            spinResult.forEach((icon, position) => {
              if (icon.id.includes('WL') && position < 15) {
                wildPositions.push(position);
              }
            });
            dragonAttackEndAfter = Dragon.the.setAnimation(BonusDragonMovements.head_b_attack).animationEnd;

            this.playDragonAttackSound();
            this.wildPositions = wildPositions;

            this.activeTimeoutId = setTimeout(() => {
              this.reels.forEach((_reel, i) => {
                if (this.reels[i]!.animation!.getDisappearing()) {
                  this.reels[i]!.animation!.getDisappearing().duration = 0;
                }
                if (this.reels[i]!.animation!.getWaiting()) {
                  this.reels[i]!.animation!.getWaiting().duration = 0;
                }
                if (this.reels[i]!.animation!.getAppearing()) {
                  this.reels[i]!.animation!.getAppearing().duration = 0;
                }
              });
            }, dragonAttackEndAfter * 1000);
          } else if (j === 0 && this.isOnlyWildsInReel(j, spinResult)) {
            dragonAttackEndAfter = Dragon.the.setAnimation(BonusDragonMovements.left_arm_attack).animationEnd;

            this.playDragonAttackSound();
            this.wildPositions = [0, 5, 10];

            this.activeTimeoutId = setTimeout(() => {
              if (this.reels[j as number]!.animation!.getDisappearing()) {
                this.reels[j as number]!.animation!.getDisappearing().duration = 0;
              }
              if (this.reels[j as number]!.animation!.getWaiting()) {
                this.reels[j as number]!.animation!.getWaiting().duration = 0;
              }
              if (this.reels[j as number]!.animation!.getAppearing()) {
                this.reels[j as number]!.animation!.getAppearing().duration = 0;
              }
            }, dragonAttackEndAfter * 1000);
          } else if (j === 2 && !this.isWildsOnDifferentReels(spinResult) && this.isOnlyWildsInReel(j, spinResult)) {
            dragonAttackEndAfter = Dragon.the.setAnimation(BonusDragonMovements.head_a_attack).animationEnd;

            this.playDragonAttackSound();
            this.wildPositions = [2, 7, 12];

            this.activeTimeoutId = setTimeout(() => {
              if (this.reels[j as number]!.animation!.getDisappearing()) {
                this.reels[j as number]!.animation!.getDisappearing().duration = 0;
              }
              if (this.reels[j as number]!.animation!.getWaiting()) {
                this.reels[j as number]!.animation!.getWaiting().duration = 0;
              }
              if (this.reels[j as number]!.animation!.getAppearing()) {
                this.reels[j as number]!.animation!.getAppearing().duration = 0;
              }
            }, dragonAttackEndAfter * 1000);
          } else if (j === 4 && !this.isWildsOnDifferentReels(spinResult) && this.isOnlyWildsInReel(j, spinResult)) {
            dragonAttackEndAfter = Dragon.the.setAnimation(BonusDragonMovements.right_arm_attack).animationEnd;

            this.playDragonAttackSound();
            this.wildPositions = [4, 9, 14];

            this.activeTimeoutId = setTimeout(() => {
              if (this.reels[j as number]!.animation!.getDisappearing()) {
                this.reels[j as number]!.animation!.getDisappearing().duration = 0;
              }
              if (this.reels[j as number]!.animation!.getWaiting()) {
                this.reels[j as number]!.animation!.getWaiting().duration = 0;
              }
              if (this.reels[j as number]!.animation!.getAppearing()) {
                this.reels[j as number]!.animation!.getAppearing().duration = 0;
              }
            }, dragonAttackEndAfter * 1000);
          }
        }

        appearingAnimation.addOnStart(() => {
          eventManager.emit(EventTypes.SET_COLLECT_VALUE, 0);
          this.reels[j as number]!.changeState(ReelState.APPEARING);
          const delay = isStopped ? 0 : 100;

          const delayBeforeRunLanding = setTimeout(() => {
            // this.handleLandingReel(j);
            clearTimeout(delayBeforeRunLanding);
          }, delay);
        });
        appearingAnimation.addOnChange(() => {
          this.reels[j as number]!.slots.forEach((slot, _i) => {
            slot.y = this.reels[j as number]!.getSlotY(slot);
          });
        });
        appearingAnimation.addOnComplete(() => {
          this.reels[j as number]!.changeState(ReelState.IDLE);
          eventManager.emit(EventTypes.REEL_LANDED, j);
        });
      }

      reel.animation?.appendAnimation(appearingAnimation);
    }

    setCurrentReelSetId(result.bet.reelSet.id);
  }

  private playDragonAttackSlotsAnimations(positions: number[]) {
    BaseWinSlotsPresentation.the.triggerWildAnimationOnDragonAttack(positions);
  }

  private playDragonAttackSound() {
    AudioApi.play({ type: ISongs.DragonAttackStart });

    switch (Dragon.the.dragonType) {
      case BonusDragons.fire:
        AudioApi.play({ type: ISongs.FireDragonAttack });
        break;
      case BonusDragons.ice:
        AudioApi.play({ type: ISongs.IceDragonAttack });
        break;
      case BonusDragons.thunder:
        AudioApi.play({ type: ISongs.ThunderDragonAttack });
        break;
    }
  }

  private isWildsOnDifferentReels(spinResult: Icon[]): boolean {
    const reelIds: number[] = [0, 2, 4];
    const symbolsInARow = 5;
    let wildCount = 0;
    let reelsWithWildCount = 0;

    for (let i = 0; i < reelIds.length; i++) {
      const reelId = reelIds[i] as number;
      let isWildOnReel = false;

      [spinResult[reelId], spinResult[symbolsInARow + reelId], spinResult[symbolsInARow * 2 + reelId]].forEach(
        (icon) => {
          if (icon?.id.includes('WL')) {
            wildCount = wildCount + 1;
            isWildOnReel = true;
          }
        },
      );

      if (isWildOnReel) {
        reelsWithWildCount = reelsWithWildCount + 1;
      }
    }

    return (
      (wildCount >= 2 &&
        !this.isOnlyWildsInReel(0, spinResult) &&
        !this.isOnlyWildsInReel(2, spinResult) &&
        !this.isOnlyWildsInReel(4, spinResult)) ||
      (wildCount >= 2 && reelsWithWildCount > 1)
    );
  }

  private isOnlyWildsInReel(reelId: number, spinResult: Icon[]): boolean {
    const symbolsInARow = 5;

    return [spinResult[reelId], spinResult[symbolsInARow + reelId], spinResult[symbolsInARow * 2 + reelId]].every(
      (icon) => icon?.id.includes('WL'),
    );
  }

  private handleLandingReel(reelId: number): void {
    const betResult = getBetResult(setBetResult());
    const slotId = betResult.bet.result.reelPositions[reelId as number];

    if (this.isStickyCollectOnLastReel(reelId)) {
      return;
    }

    for (let i = -1; i < SLOTS_PER_REEL_AMOUNT - 1; i++) {
      const stoppedSlotId =
        (slotId! + this.reels[reelId as number]!.slots.length - i) % this.reels[reelId as number]!.slots.length;
      this.reels[reelId as number]!.slots[stoppedSlotId as number]!.onSlotStopped();
    }
  }

  private isStickyCollectOnLastReel(reelId: number): boolean {
    return Logic.the.controller.gameMode === GameMode.FREE_SPINS && reelId === 4;
  }

  public setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const reel = this.reels[x as number] as BaseReel;
      const slot = reel.slots.find((slot) => slot.id === (reel.stopPosition + y + reel.size - 1) % reel.size)!;
      if (slot) slot.visible = visibility;
    });
  }
}

export default BaseReelsContainer;
