import { ITrackEntry, Spine } from 'pixi-spine';
import { Container, Loader } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setCurrentBonus } from '../../gql/cache';
import { Logic } from '../../logic';
import { eventManager } from '../config';

import { BaseDragon, BaseDragonMovements, BonusDragonMovements, BonusDragons } from './dragon.model';

export class Dragon extends Container {
  private static getDragonSpineData = (dragon: BonusDragons | BaseDragon) => Loader.shared.resources[dragon]!.spineData;

  static the: Dragon;
  dragonType: BonusDragons | BaseDragon;
  private dragon = new Spine(Dragon.getDragonSpineData(BaseDragon.kid)!);
  constructor(defaultDragon: BonusDragons | BaseDragon = BaseDragon.kid) {
    super();
    this.dragonType = defaultDragon;
    this.setDragon(defaultDragon);
    Dragon.the = this;

    // setTimeout(() => this.setDragon(Dragons.ice), 15000);
    // setTimeout(() => this.setDragon(Dragons.thunder), 30000);
  }

  public setDragon(dragon: BonusDragons | BaseDragon): void {
    this.removeChildren();
    this.dragonType = dragon;
    this.dragon = new Spine(Dragon.getDragonSpineData(dragon)!);
    this.addChild(this.dragon);
    this.setupMoveListener();
    // this.simulateTestMovements(dragon);

    this.dragon.state.setAnimation(0, BonusDragonMovements.idle, true);
  }

  public setAnimation(movements: BonusDragonMovements | BaseDragonMovements, isLoop = false): ITrackEntry {
    return this.dragon.state.setAnimation(0, movements, isLoop);
  }

  private setupMoveListener(): void {
    this.dragon.state.addListener({
      complete: () => {
        if (this.isFreeSpinsMode) {
          AudioApi.play({ type: ISongs.FeatureDragonWing, stopPrev: true });
        }
        this.setAnimation(BonusDragonMovements.idle, true);
      },
      event: (_entry, event) => {
        switch (event.data.name) {
          case 'vfx_wild':
            eventManager.emit(EventTypes.DRAGON_ATTACK);
            break;
          default:
            break;
        }
      },
    });
  }

  private get isFreeSpinsMode(): boolean {
    return Boolean(Logic.the.controller.gameMode === GameMode.FREE_SPINS && setCurrentBonus().isActive);
  }

  private simulateTestMovements(dragon: BonusDragons | BaseDragon): void {
    if (dragon === BaseDragon.kid) {
      setTimeout(() => this.setAnimation(BaseDragonMovements.attack), 3000);
      setTimeout(() => this.setAnimation(BaseDragonMovements.attack), 6000);
      setTimeout(() => this.setAnimation(BaseDragonMovements.attack), 9000);
      return;
    }
    setTimeout(() => this.setAnimation(BonusDragonMovements.head_b_attack), 3000);
    setTimeout(() => this.setAnimation(BonusDragonMovements.right_arm_attack), 6000);
    setTimeout(() => this.setAnimation(BonusDragonMovements.left_arm_attack), 9000);
    setTimeout(() => this.setAnimation(BonusDragonMovements.head_b_attack), 12000);
  }
}
