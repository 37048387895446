import { TextStyle } from 'pixi.js';

import { GameMode } from '../../global.d';
import type { FormFactorSizes } from '../awakening-meter/awakening-meter.model';
import { BonusDragons } from '../dragon/dragon.model';

export const dragonDescriptions: Record<BonusDragons, string> = {
  [BonusDragons.fire]: 'fireDragonDescription',
  [BonusDragons.ice]: 'iceDragonDescription',
  [BonusDragons.thunder]: 'thunderDragonDescription',
};

export const dragonSelectionToGameModeMap: Record<BonusDragons, GameMode> = {
  [BonusDragons.fire]: GameMode.FREE_SPINS_FIRE,
  [BonusDragons.ice]: GameMode.FREE_SPINS_ICE,
  [BonusDragons.thunder]: GameMode.FREE_SPINS_THUNDER,
};

export const freeSpinsBg = 'freeSpinsBgTexture';
export const freeSpinsDragonSprite = 'freeSpinsDragonSprite';

export const freeSpinsPopupSizes: FormFactorSizes = {
  pc: { x: 596, y: 256, width: 728, height: 512 },
  portrait: { x: 'center', y: 'center', width: 500, height: 360 },
  landscape: { x: 'center', y: 'center', width: 500, height: 360 },
};

export const dragonPopupTitleText = new TextStyle({
  align: 'center',
  fill: '#fbff34',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 28,
  lineHeight: 32,
  fontWeight: 'bold',
  stroke: '#493108',
  strokeThickness: 4,
  whiteSpace: 'normal',
});

export const multiplierText = {
  default: {
    align: 'center',
    fill: '#fbff34',
    fontFamily: 'NotoSans-SemiCondensedBold',
    fontSize: 36,
    fontWeight: 'bold',
    stroke: '#493108',
    strokeThickness: 8,
    whiteSpace: 'normal',
  },
  count: {
    align: 'center',
    fontSize: 50,
  },
};

export const dragonText = new TextStyle({
  align: 'center',
  fill: '#493108',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 24,
  lineHeight: 32,
  fontWeight: 'bold',
  whiteSpace: 'normal',
});

export const dragonActiveText = new TextStyle({
  align: 'center',
  fill: '#fbff34',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 38,
  lineHeight: 52,
  fontWeight: 'bold',
  stroke: '#493108',
  strokeThickness: 4,
  whiteSpace: 'normal',
});
