import { TextStyle } from 'pixi.js';

export const buyFeatureTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 32,
  lineHeight: 34,
  lineJoin: 'round',
  fill: '#fff067',
  stroke: '#820000',
  strokeThickness: 2,
});
export const buyFeatureHoverTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  dropShadowColor: '#505050',
  fontSize: 32,
  lineHeight: 34,
  lineJoin: 'round',
  fill: '#ffffff',
  stroke: '#820000',
  strokeThickness: 2,
});
export const buyFeatureClickTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 32,
  lineHeight: 34,
  lineJoin: 'round',
  fill: '#fff067',
  stroke: '#820000',
  strokeThickness: 2,
});
export const buyFeatureDisableTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 32,
  lineHeight: 34,
  lineJoin: 'round',
  fill: '#aaaaaa',
  stroke: '#000000',
  strokeThickness: 2,
});
