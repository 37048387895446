import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setAvailableBonuses,
  setBetAmount,
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeSpinsTotalWin,
  setGameHistory,
  setIsBuyFeatureFailed,
  setIsFreeSpinsWin,
  setIsProceedToGame,
  setIsSlotBusy,
  setIsSpinAndGrabWin,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setLastSpinData,
  setLostSpinsInARowCount,
  setSkipIntroScreen,
  setSlotConfig,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { AwakeningMeter } from '../../slotMachine/awakening-meter/awakening-meter';
import { WinStages, eventManager } from '../../slotMachine/config';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import { getBetResult, getWinStage, saveReelPosition } from '../../utils';
import { getSpinResult } from '../../utils/getSpinResult';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public override enterInitState(_prevState: States): void {
    if (!setSkipIntroScreen()) {
      Logic.the.changeState(States.INTRO);
      return;
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.BROKEN_GAME);
      return;
    }

    Logic.the.changeState(States.IDLE);
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProceedToGame(true);
    }
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.init();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterBrokenGameState(_prevState: States): void {
    setIsProceedToGame(true);
    if (!setCurrentBonusId()) {
      const bonus = setCurrentBonus();
      SlotMachine.the().onBrokenGame(bonus);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(bonus.gameMode, { bonus, immediate: true, ignoreHideEgg: true });
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);

      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (prevState === States.TRANSITION) {
      setIsSpinInProgress(false);
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      if (setCurrentBonusId()) {
        return;
      }
    }

    if (prevState === States.INIT || prevState === States.INTRO || setIsBuyFeatureFailed()) {
      setIsBuyFeatureFailed(false);
      // const debug = new Debug();
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    if (prevState === States.BROKEN_GAME) {
      setTimeout(() => {
        eventManager.emit(EventTypes.TOGGLE_SPIN);
      }, 100);

      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
      // AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
    }, 30000);
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory(prevState === States.TRANSITION);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());

    this.checkBaseWin(betResult);
  }

  public override enterWinLinePresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { paylines } = betResult;
    const { winCoinAmount } = betResult.bet.result;
    // const multiplier = normalizeCoins(winCoinAmount) / normalizeCoins(setBetAmount());
    // if (multiplier >= 5 && multiplier < 10) {
    //   AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
    // }
    // if (multiplier >= 2 && multiplier < 5) {
    //   AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
    // }
    // if (multiplier > 0 && multiplier < 2) {
    //   AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
    // }

    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        Logic.the.changeState(States.AFTER_WIN);
      });
      setTimeout(() => eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount), 1000);
    } else {
      eventManager.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
    }

    eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setWinAmount(winCoinAmount);
    setLastRegularWinAmount(winCoinAmount);
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    const isFeatureWin = result.bet.data.bonuses.length > 0;

    if (isFeatureWin) {
      setIsFreeSpinsWin(true);
      setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
      setTimeout(() => {
        Logic.the.skipWinAnimation();
        Logic.the.changeState(States.TRANSITION);
        Logic.the.changeGameMode(GameMode.FREE_SPINS);
        setAvailableBonuses(result.bet.data.bonuses);
      }, 1000);

      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps): void {
    if (prevGameMode !== GameMode.BUY_FEATURE) {
      AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
    }
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_EYES);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    setIsSpinAndGrabWin(false);
    if (prevGameMode === GameMode.FREE_SPINS) {
      setWinAmount(setFreeSpinsTotalWin());
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitController(nextGameMode: GameMode): void {
    if (nextGameMode !== GameMode.BUY_FEATURE) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    }
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
  }

  public override setResult(result: ISettledBet): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });

    result.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setBetResult(result);

    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });
  }

  private handleHistory(skipSoundChange: boolean): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];
    const isThirdWinInRow = lastThreeSpins.every((val) => Boolean(val));
    const isThirdLoseInRow = lastThreeSpins.every((val) => !val);
    const isWinThreeTimesBiggerThanBet = setBetAmount() * 3 <= win;

    if (!skipSoundChange) {
      if (isWinThreeTimesBiggerThanBet || isThirdWinInRow) {
        // AudioApi.fadeOut(500, ISongs.BGM_BG_Base_Loop);
        AudioApi.fadeIn(500, ISongs.BGM_BG_Melo_Loop);
      }

      if (isThirdLoseInRow) {
        AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
        // AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
      }
    }

    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
    saveReelPosition(betResult.bet.result.reelPositions);
  }

  private checkBaseWin(betResult: ISettledBet): void {
    if (betResult.paylines.length) {
      const awakeningLevel = betResult.bet.result.winCoinAmount
        ? setLostSpinsInARowCount(0)
        : setLostSpinsInARowCount(setLostSpinsInARowCount() + 1);
      this.updateAwakeningMeter(awakeningLevel);
      Logic.the.changeState(States.WIN_LINE_PRESENTATION);
    } else {
      this.updateAwakeningMeter(setLostSpinsInARowCount(setLostSpinsInARowCount() + 1));
      Logic.the.changeState(States.IDLE);
    }
  }

  private updateAwakeningMeter(awakeningLevel: number) {
    AwakeningMeter.the.updateCount(awakeningLevel);
  }
}
