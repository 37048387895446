import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';
import type * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import type { Play } from '@phoenix7dev/audio-api/dist/d';

import { ISongs, SlotId } from '../config';
import { EventTypes, FeatureState, GameMode, ISettledBet, buyFeatureBonusesId } from '../global.d';
import {
  setAvailableBonuses,
  setBetAmount,
  setBetResult,
  setCoinAmount,
  setCurrentBonus,
  setIsDuringBigWinLoop,
  setIsSuspended,
  setSlotConfig,
  setUserLastBetResult,
} from '../gql/cache';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MEGA_WIN_AMOUNT_LIMIT,
  WinStages,
  eventManager,
} from '../slotMachine/config';
import { ModalService } from '../slotMachine/popups/ModalService';

import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
}

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const getBetResult = (betResult: ISettledBet | null): ISettledBet => {
  if (betResult === null) throw new Error('Invalid bet result');
  return betResult;
};

export const normalizePosition = (size: number, pos: number): number => {
  while (pos < 0) pos += size;
  return pos % size;
};

export const normalize = (coord: number, layout: string[]) => {
  return coord < 0 ? layout.length - (Math.abs(coord) % layout.length) : coord % layout.length;
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  return buyFeatureBonusesId[featureState as FeatureState]!;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};

export const isBuyFeaturePopupOpened = (): boolean => {
  return Boolean(ModalService.the.isModalVisible);
};

export const getWinStage = (winAmount: number): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const multiplier = normalizeCoins(winAmount) / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};
export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.BASE_GAME;
};
export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lines.length)) / 100;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isPopupOpened,
  isModalOpeningInProgress,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isPopupOpened: boolean;
  isModalOpeningInProgress: boolean;
}): boolean => {
  if (isBuyFeaturePopupOpened()) {
    return false;
  }

  if (isBuyFeatureMode(gameMode) && isFreeSpinsWin) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  if (isModalOpeningInProgress) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (options: Partial<DropShadowFilterOptions>): PIXI.Filter => {
  return new DropShadowFilter(options) as PIXI.Filter;
};

export const getBGMSoundByGameMode = (mode: GameMode): ISongs => {
  switch (mode) {
    case GameMode.BASE_GAME:
      return ISongs.BGM_BG_Base_Loop;
    case GameMode.FREE_SPINS:
      return ISongs.BGM_FS_Loop;

    default:
      return ISongs.BGM_BG_Base_Loop;
  }
};
export const handleChangeRestriction = (mode: GameMode): void => {
  setIsSuspended(false);
  AudioApi.unSuspend();

  AudioApi.changeRestriction(false, []);
  const listToPlay: Play[] = [];
  if (setIsDuringBigWinLoop()) {
    listToPlay.push({ type: ISongs.BigWin_Loop });
  }
  switch (mode) {
    case GameMode.BASE_GAME:
      listToPlay.push({ type: ISongs.BGM_BG_Base_Loop }, { type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
      break;
    case GameMode.FREE_SPINS:
      if (setAvailableBonuses().length) {
        listToPlay.push({ type: ISongs.BGM_SD_Loop });
      } else {
        listToPlay.push({ type: ISongs.BGM_FS_Loop });
      }
      break;
    default:
      listToPlay.push({ type: ISongs.BGM_BG_Base_Loop }, { type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
      break;
  }
  AudioApi.playlist({ list: listToPlay });
};
export const getSlotOrderBySlotId = (slotId: SlotId): number => {
  switch (slotId) {
    case SlotId.C:
      return 11;
    case SlotId.SC:
      return 10;
    case SlotId.WL:
      return 9;
    default:
      return 0;
  }
};

export const getLayerOrderByName = (name: string): number => {
  switch (name) {
    case 'Background':
      return 8;
    case 'SafeArea':
      return 9;
    case 'Backdrop':
      return 10;
    case 'BigWinContainer':
      return 11;
    default:
      return 0;
  }
};

export const is4SlotsReel = (reelId: number) => {
  const reelsIdsWith4SlotsPerReel = [1, 3];

  return reelsIdsWith4SlotsPerReel.includes(reelId);
};

export const isSlotFrom4SlotsReel = (slotPosition: number) => {
  const slotPositionsFrom4SlotsReel = [1, 3, 6, 8, 11, 13, 16, 18];

  return slotPositionsFrom4SlotsReel.includes(slotPosition);
};

export const urlSearchParams = new URLSearchParams(window.location.search);

export const isDemo = urlSearchParams.has('isDemo');

export const getAwakeningMeterStateForBet = () => {
  if (setBetResult()) {
    return setBetResult()?.bet.data.features.gameRoundStore.gamesWithoutWinPerCoinAmount[setCoinAmount()] || 0;
  }

  return setUserLastBetResult().data.features.gameRoundStore.gamesWithoutWinPerCoinAmount[setCoinAmount()] || 0;
};

export const getAwakeiningMeterStateForFreeSpins = (): number => {
  const isOnDragonPickModal = Boolean(setAvailableBonuses()?.length > 1);
  const isDragonSelected = Boolean(setCurrentBonus().isActive);

  if (isOnDragonPickModal) {
    return (setAvailableBonuses()[0] && setAvailableBonuses()[0]?.roundsLeft) || 0;
  }

  if (isDragonSelected) {
    return setCurrentBonus().roundsLeft;
  }

  return 0;
};

export const isBrokenGameOnFreeSpins = () => {
  return Boolean(setAvailableBonuses()?.length > 1) || setCurrentBonus().isActive;
};

export const fallBackReelPosition = () => {
  eventManager.emit(EventTypes.ROLLBACK_REELS);
};
