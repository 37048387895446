import type React from 'react';
import type { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import { features } from './Features.model';
import styles from './info.module.scss';

export interface MyCustomCSS extends CSSProperties {
  'container-type': string | number;
}

const Features: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['gameRules']}>
      {features.map(({ title, description, isImage }) => (
        <section key={title} className={styles['feature']}>
          <h1>{t(title)}</h1>
          {isImage && (
            <div
              className={`${styles['container']}`}
              style={
                {
                  'backgroundImage': `url('${Resources.getSource(ResourceTypes.buyFeature)}')`,
                  'width': '150px',
                  'height': '150px',
                  'backgroundPosition': 'center',
                  'backgroundSize': 'contain',
                  'container-type': 'inline-size',
                  'margin': '0 auto',
                } as MyCustomCSS
              }
            >
              <p className={`${styles['buyfeaturetext']}`} dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />
            </div>
          )}
          <p dangerouslySetInnerHTML={{ __html: t(description) }}></p>
        </section>
      ))}
    </div>
  );
};

export default Features;
