import i18n from 'i18next';
import { Container, Text } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import type { IBonus } from '../../../global';
import {
  setBetAmount,
  setBetResult,
  setBonuses,
  setCoinValue,
  setLostSpinsInARowCount,
  setUserBalance,
} from '../../../gql/cache';
import { getAwakeningMeterStateForBet, normalizeCoins } from '../../../utils';
import { AwakeningMeter } from '../../awakening-meter/awakening-meter';
import { TextField } from '../../components/TextField';
import { ModalService } from '../../popups/ModalService';

import { BetControl } from './betControl/betControl';
import { BuyFeatureConfirmPopup } from './buyFeatureConfirmPopup';
import { buyFeatureWhiteText, buyFeatureYellowText } from './buyFeaturePopup.model';
import { BuyFeatureWrapper } from './buyFeatureWrapper';

export class BuyFeaturePopup extends Container {
  public override name = 'BuyFeaturePopup';
  private wrapper = new BuyFeatureWrapper(() => this.openConfirmPopup());
  private betControl = new BetControl((betAmount) => this.changeBetCost(betAmount));
  private totalCost = 0;
  private timeoutID: NodeJS.Timeout | null = null;

  constructor() {
    super();
    this.addChild(this.wrapper);
    this.wrapper.add(this.getTitle(), this.getBetTitle(), this.getBetContainer());
    this.changeBetCost(setBetAmount(), true);
  }

  private openConfirmPopup(): void {
    AudioApi.play({ type: ISongs.SFX_UI_General });
    ModalService.the.hide();
    ModalService.the.open(new BuyFeatureConfirmPopup(this.totalCost));
  }

  private changeBetCost(betAmount: number, ignoreAwakeningMeterUpdate = false): void {
    const betCost = betAmount * this.getCoinAmount();
    const balance = setBetResult() ? setBetResult()?.balance.settled.amount! : setUserBalance().balance.amount;
    const balanceCost = balance / setCoinValue();
    this.wrapper.changeTotalCost(normalizeCoins(betCost));
    betCost > balanceCost ? this.wrapper.okBtn.disable() : this.wrapper.okBtn.enable();
    this.totalCost = normalizeCoins(betCost);

    if (!ignoreAwakeningMeterUpdate) {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      this.timeoutID = setTimeout(() => {
        setLostSpinsInARowCount(getAwakeningMeterStateForBet());
        AwakeningMeter.the.updateCount(getAwakeningMeterStateForBet(), false, true);
      }, 100);
    }
  }

  private getCoinAmount = (): number => {
    const bonus = setBonuses()[0] as IBonus;

    return bonus.coinAmount;
  };

  private getBetContainer(): Container {
    const betContainer = new Container();

    betContainer.addChild(this.betControl);
    betContainer.y = 240 + betContainer.height / 2;

    return betContainer;
  }
  private getBetTitle(): Container {
    const textField = new TextField(i18n.t<string>('buyFeatureBet'), 44, 38, buyFeatureYellowText);
    textField.text.y = 187;

    return textField.getText();
  }

  private getTitle(): Text {
    const textField = new TextField(i18n.t<string>('buyFeatureTitle'), 209, 52, buyFeatureWhiteText);
    textField.text.y = 135;

    return textField.getText();
  }
}
