import i18n from 'i18next';
import MultiStyleText from 'pixi-multistyle-text';
import { Container, isMobile } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setIsLeftHandMode } from '../../gql/cache';
import { multiplierPosition } from '../awakening-meter/awakening-meter.model';
import { eventManager } from '../config';

import { multiplierText } from './freeSpins.model';

export class MultiplierTitle extends Container {
  public override name = 'MultiplierTitle';
  private textField = this.getTextField();
  public static the: MultiplierTitle;

  constructor() {
    super();

    MultiplierTitle.the = this;
    this.zIndex = 1;
    this.visible = false;

    this.addChild(this.textField);
    this.textField.anchor.set(0.5, 0.5);

    this.setSizes();
    eventManager.on(EventTypes.RESIZE, () => this.setSizes());
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
  }

  protected onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = false;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      default:
        this.visible = false;
        break;
    }
  }

  public change(multiplier: number): void {
    if (!this.visible) {
      this.visible = true;
    }

    this.textField.text = i18n.t<string>('multiplierDescription', { multiplier });
  }

  private setSizes(): void {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isPortrait = isMobile.any && screenHeight > screenWidth;

    if (!isMobile.any) {
      const { x, y } = multiplierPosition.pc;
      this.position.set(x, y);

      return;
    }
    this.getMobilePosition(isPortrait, setIsLeftHandMode());
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLeftMode) => this.getMobilePosition(isPortrait, isLeftMode));
  }

  private getMobilePosition(isPortrait: boolean, isLeftMode: boolean) {
    const { leftMode, rightMode } = multiplierPosition[isPortrait ? 'portrait' : 'landscape'];
    const { x, y } = isLeftMode ? leftMode : rightMode;
    this.position.set(x, y);
  }

  private getTextField(): MultiStyleText {
    return new MultiStyleText(i18n.t<string>('selectDragonTitle'), multiplierText);
  }
}
