import { Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setCurrentBonusId,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsSpinInProgress,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { awakeningMeterPosition } from '../awakening-meter/awakening-meter.model';
import { SpriteButton, SpriteButtonState } from '../components/SpriteButton';
import { TextField } from '../components/TextField';
import { eventManager } from '../config';
import { ModalService } from '../popups/ModalService';

import {
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
} from './buyFeatureBtn.model';
import { BuyFeaturePopup } from './buyFeaturePopup/buyFeaturePopup';

export class BuyFeatureBtn extends SpriteButton {
  public override name = 'buyFeatureBtn';
  public override interactive = true;
  private isDisabled = false;
  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }
  constructor() {
    super({
      [SpriteButtonState.DEFAULT]: {
        texture: Texture.from(ResourceTypes.buyFeature),
        textStyle: buyFeatureTextStyle,
      },
      [SpriteButtonState.HOVER]: {
        texture: Texture.from(ResourceTypes.buyFeatureHover),
        textStyle: buyFeatureHoverTextStyle,
      },
      [SpriteButtonState.PRESSED]: {
        texture: Texture.from(ResourceTypes.buyFeaturePressed),
        textStyle: buyFeatureClickTextStyle,
      },
      [SpriteButtonState.DISABLED]: {
        texture: Texture.from(ResourceTypes.buyFeatureDisable),
        textStyle: buyFeatureDisableTextStyle,
      },
      onHover: () => AudioApi.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        if (this.isOpenAllowed) {
          setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
          setIsOpenBetSettingsModal(false);
          setIsOpenAutoplayModal(false);
          setTimeout(() => this.showPopup(), 0);
        }
      },
      onTouchStart: () => {
        if (this.isOpenAllowed) {
          setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
          setIsOpenBetSettingsModal(false);
          setIsOpenAutoplayModal(false);
          setTimeout(() => this.showPopup(), 0);
        }
      },
      textFiled: BuyFeatureBtn.getTextField(),
    });
    this.zIndex = 1;

    this.setDisableState(Boolean(setCurrentBonusId()));
    this.setSizes();
    eventManager.on(EventTypes.RESIZE, () => this.setSizes());
    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => this.setDisableState(disable));
    eventManager.on(EventTypes.CHANGE_MODE, (settings: { mode: GameMode }) => this.changeVisibility(settings));
  }

  private setSizes(): void {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const isPortrait = isMobile.any && screenHeight > screenWidth;

    this.width = 250;
    this.height = 250;

    if (!isMobile.any) {
      this.position.set(-80, 400);

      return;
    }

    this.getMobilePosition(isPortrait, setIsLeftHandMode());

    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLeftMode) => this.getMobilePosition(isPortrait, isLeftMode));
  }

  private getMobilePosition(isPortrait: boolean, isLeftMode: boolean) {
    const { leftMode, rightMode } = awakeningMeterPosition[isPortrait ? 'portrait' : 'landscape'];
    const { x, y } = isLeftMode ? rightMode : leftMode;
    this.position.set(x, y);
  }

  private static getTextField(): TextField {
    const text = new TextField(i18n.t<string>('buyFeatureBtn'), 139, 68, buyFeatureTextStyle);

    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(0, 10);

    return text;
  }
  private showPopup(): void {
    AudioApi.play({ type: ISongs.BuyButton });
    ModalService.the.open(new BuyFeaturePopup());
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);

    setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
  }

  private get isOpenAllowed() {
    return (
      !this.isDisabled &&
      setIsModalOpeningInProgress() === ModalOpeningTypes.NONE &&
      !setIsAutoSpins() &&
      !setIsSpinInProgress() &&
      !setCurrentBonusId()
    );
  }

  private setDisableState(disabled: boolean): void {
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disabled;
    disabled ? this.disable() : this.enable();
  }

  private changeVisibility(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }
}
